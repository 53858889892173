import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import UIComponentMixin from "../UIComponentMixin";

// @vue/component
export default {
  name: "UiOneCheckbox",
  components: {
    PuxTranslate,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    statusChecked() {
      return this.model === true;
    },

    labelLocal() {
      if (this.options.required) {
        return this.addAsteriskToLastTag(this.options.label);
      }

      return this.options.label;
    },
  },
  methods: {
    toggleModel($event) {
      if (this.isDisabled) {
        return;
      }
      setTimeout(() => {
        const MODEL = !this.statusChecked;
        this.input({
          model: MODEL,
          id: this.options.id,
          $event: $event,
          options: this.options,
        });
        this.change({
          model: MODEL,
          id: this.options.id,
          options: this.options,
          $event: $event,
        });
      });
    },

    addAsteriskToLastTag(html) {
      const openingTagRegex = /<(?!!--)([^/][^>]*)>/g;
      let lastOpeningTagMatch;
      let match = openingTagRegex.exec(html);
      while (match) {
        lastOpeningTagMatch = match;
        match = openingTagRegex.exec(html);
      }

      if (!lastOpeningTagMatch) {
        return html;
      }

      const lastTagName = lastOpeningTagMatch[1].split(" ")[0];
      const lastOpeningTagIndex = lastOpeningTagMatch.index;
      const closingTagRegex = new RegExp(`</${ lastTagName }>`, "i");
      const closingTagMatch = closingTagRegex.exec(html.slice(lastOpeningTagIndex));
      if (closingTagMatch) {
        const insertPosition = closingTagMatch.index + lastOpeningTagIndex;

        return `${ html.slice(0, insertPosition) }*${ html.slice(insertPosition) }`;
      }

      return html;
    }
  },
};
