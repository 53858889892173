import {
  computed,
  ref,
} from "vue";

import {
  cloneDeep,
  forEach,
  size,
} from "lodash-es";

export default function ModelAPI() {
  const model = ref({});
  const modelDefault = ref({});

  const getModelForSave = () => {
    const MODEL_DIFF = {};
    forEach(model.value, (value, key) => {
      if (modelDefault.value[key] !== value) {
        MODEL_DIFF[key] = value;
      }
    });

    return MODEL_DIFF;
  };

  const modelChanged = computed(() => {
    return getModelForSave();
  });

  const countChangedTranslations = computed(() => {
    return size(modelChanged.value);
  });

  const clearModel = () => {
    model.value = {};
    modelDefault.value = {};
  };

  const resetModelDefault = () => {
    modelDefault.value = cloneDeep(model.value);
  };

  return {
    clearModel,
    countChangedTranslations,
    getModelForSave,
    model,
    modelChanged,
    modelDefault,
    resetModelDefault,
  };
}
