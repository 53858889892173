import {
  ref,
} from "vue";

import AHttpAPI, {
  getHttp,
} from "aloha-vue/src/compositionAPI/AHttpAPI";
import ATranslationAPI, {
  setLanguage,
} from "aloha-vue/src/ATranslation/compositionAPI/ATranslationAPI";

import allLanguages from "aloha-vue/src/i18n/allLanguages";
import Cookies from "js-cookie";
import {
  find,
  forEach,
  sortBy,
} from "lodash-es";

const LANGUAGE_DEFAULT = "de-de";
const ONE_YEAR = 365;
const ALOHA_LANGUAGES = [
  "ar",
  "de",
  "en",
  "es",
  "fr",
  "hr",
  "it",
  "ru",
];

const languages = ref([]);
const modelLanguage = ref(LANGUAGE_DEFAULT);

export default function TranslateAPI() {
  const {
    getHttp,
  } = AHttpAPI();

  const {
    setI18n,
    updateTranslation,
  } = ATranslationAPI();

  const groupTranslateByLang = response => {
    const GROUP_TRANSLATE = {};
    const ALOHA_LANGUAGE_NAME = getAlohaLanguageName(modelLanguage.value);
    if (allLanguages?.[ALOHA_LANGUAGE_NAME]) {
      GROUP_TRANSLATE[modelLanguage.value] = allLanguages[ALOHA_LANGUAGE_NAME];
    }

    forEach(response, item => {
      if (!GROUP_TRANSLATE[item.ubs_sprache]) {
        GROUP_TRANSLATE[item.ubs_sprache] = {};
      }
      GROUP_TRANSLATE[item.ubs_sprache][item.ubs_text] = item.ubs_uebersetzung;
    });
    return GROUP_TRANSLATE;
  };

  const setTranslation = response => {
    setI18n(groupTranslateByLang(response));
  };

  const loadTranslate = () => {
    return getHttp({
      url: "katalog/",
      urlParams: {
        key: "uebersetzungen",
        ubs_sprache: modelLanguage.value,
      },
    }).then(
      response => {
        setTranslation(response);
        updateTranslation();
      }
    );
  };

  const changeLanguage = () => {
    setTimeout(() => {
      Cookies.set("language", modelLanguage.value, { expires: ONE_YEAR });
      location.reload();
    });
  };

  return {
    changeLanguage,
    getAlohaLanguageName,
    languages,
    loadTranslate,
    modelLanguage,
  };
}

export function setLanguageProandi() {
  return getHttp({
    url: "katalog/",
    urlParams: {
      key: "sprache",
      ksp_aktiv: true,
    },
  }).then(
    response => {
      languages.value = sortBy(response, ["ksp_prio"]);
      setLanguageFormBrowserOptions(response);
      setLanguage(modelLanguage.value);
      document.documentElement.lang = getAlohaLanguageName(modelLanguage.value);
    }
  );
}

function checkTwoLanguages(language1 = "", language2 = "") {
  const LANGUAGE1_LOWER_CASE = language1.toLowerCase();
  const LANGUAGE2_LOWER_CASE = language2.toLowerCase();

  return LANGUAGE1_LOWER_CASE.includes(LANGUAGE2_LOWER_CASE) || LANGUAGE2_LOWER_CASE.includes(LANGUAGE1_LOWER_CASE);
}

function getLanguageFromBrowserAcceptLanguage(languages) {
  let languageFromBrowserAcceptLanguage;
  forEach(navigator.languages, navigatorLanguage => {
    const LANGUAGE = find(languages, language => {
      return checkTwoLanguages(language.ksp_kuerzel, navigatorLanguage);
    });
    if (LANGUAGE) {
      languageFromBrowserAcceptLanguage = LANGUAGE.ksp_kuerzel;
      return false;
    }
  });

  return languageFromBrowserAcceptLanguage;
}

function getLanguageFormCookies(languages) {
  const LANGUAGE_FORM_COOKIES = Cookies.get("language");
  if (!LANGUAGE_FORM_COOKIES) {
    return;
  }

  let languageFormCookiesLocal;
  forEach(languages, language => {
    if (checkTwoLanguages(language.ksp_kuerzel, LANGUAGE_FORM_COOKIES)) {
      languageFormCookiesLocal = LANGUAGE_FORM_COOKIES;
      return false;
    }
  });

  return languageFormCookiesLocal;
}

function setLanguageFormBrowserOptions(languages) {
  if (languages.length) {
    modelLanguage.value = getLanguageFormCookies(languages) ||
      getLanguageFromBrowserAcceptLanguage(languages) ||
      LANGUAGE_DEFAULT;
    return;
  }

  modelLanguage.value = LANGUAGE_DEFAULT;
}

export function getAlohaLanguageName(languageProandi) {
  let alohaLanguage = "de";
  forEach(ALOHA_LANGUAGES, language => {
    if (languageProandi.includes(language)) {
      alohaLanguage = language;
      return false;
    }
  });

  return alohaLanguage;
}
