import PermissionMixin from "../../mixins/PermissionMixin";
import {
  replaceText,
  isTranslatePlaceholder,
  gettext,
} from "../../functions/utils";
import EventBus from "aloha-vue/src/utils/EventBus";
import {
  assign,
  cloneDeep,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "PuxTranslate",
  mixins: [
    PermissionMixin,
  ],
  props: {
    text: {
      type: [String, Number],
      required: false,
      default: undefined,
      info: "Placeholder für Text",
    },
    html: {
      type: [String, Number],
      required: false,
      default: undefined,
      info: "Placeholder für Html",
    },
    title: {
      type: [String, Number],
      required: false,
      default: undefined,
      info: "Placeholder für Html-Attribute 'title'",
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
      info: "Placeholder für Html-Attribute 'placeholder'",
    },
    ariaLabel: {
      type: [String, Number],
      required: false,
      default: undefined,
      info: "Placeholder für Html-Attribute 'aria-label'",
    },
    tag: {
      type: String,
      required: false,
      default: "div",
      info: "Semantisch-relevanter HTML-Tag.",
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
      info: "Extra-Objekt für Übersetzung",
    },
    attributes: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "Objekt für Html-Attribute (nicht übersetzbar)",
    },
    textBefore: {
      type: [String, Number],
      required: false,
      default: "",
      info: "Statischer Text vor {{ text }}, der man nur mit ':text' zusammen benutzen soll.",
    },
    textAfter: {
      type: [String, Number],
      required: false,
      default: "",
      info: "Statischer Text nach {{ text }}, der man nur mit ':text' zusammen benutzen soll.",
    },
  },
  emits: [
    "click",
  ],
  data() {
    return {
      statusShowPlaceholder: window.statusShowPlaceholdersTranslate,
    };
  },
  computed: {
    htmlLocal() {
      return this.getAttributeTranslate({
        value: this.html,
        statusTranslate: this.statusTranslateHtml && !this.statusShowPlaceholder,
      });
    },

    isHtmlLocal() {
      return !isNil(this.htmlLocal);
    },

    statusTranslateHtml() {
      return !(!this.html || !isTranslatePlaceholder(this.html));
    },

    textLocal() {
      return this.getAttributeTranslate({
        value: this.text,
        statusTranslate: this.statusTranslateText && !this.statusShowPlaceholder,
      });
    },

    statusTranslateText() {
      return !(!this.text || !isTranslatePlaceholder(this.text));
    },

    titleLocal() {
      return this.getAttributeTranslate({
        value: this.title,
        statusTranslate: this.statusTranslateTitle && !this.statusShowPlaceholder,
      });
    },

    statusTranslateTitle() {
      return !(!this.title || !isTranslatePlaceholder(this.title));
    },

    placeholderLocal() {
      return this.getAttributeTranslate({
        value: this.placeholder,
        statusTranslate: this.statusTranslatePlaceholder && !this.statusShowPlaceholder,
      });
    },

    statusTranslatePlaceholder() {
      return !(!this.placeholder || !isTranslatePlaceholder(this.placeholder));
    },

    ariaLabelLocal() {
      return this.getAttributeTranslate({
        value: this.ariaLabel,
        statusTranslate: this.statusTranslateAriaLabel && !this.statusShowPlaceholder,
      });
    },

    statusTranslateAriaLabel() {
      return !(!this.ariaLabel || !isTranslatePlaceholder(this.ariaLabel));
    },


    attributesLocal() {
      const $ATTRS = cloneDeep(this.$attrs) || {};
      const ATTRIBUTES = assign({}, $ATTRS, this.attributes);
      if (this.statusTranslateText) {
        ATTRIBUTES["data-translate-text"] = this.text;
      }
      if (this.statusTranslateHtml) {
        ATTRIBUTES["data-translate-html"] = this.html;
      }
      if (this.title) {
        ATTRIBUTES.title = this.titleLocal;
      }
      if (this.statusTranslateTitle) {
        ATTRIBUTES["data-translate-title"] = this.title;
      }
      if (this.placeholder) {
        ATTRIBUTES.placeholder = this.placeholderLocal;
      }
      if (this.statusTranslatePlaceholder) {
        ATTRIBUTES["data-translate-placeholder"] = this.placeholder;
      }
      if (this.ariaLabel) {
        ATTRIBUTES["aria-label"] = this.ariaLabelLocal;
      }
      if (this.statusTranslatePlaceholder) {
        ATTRIBUTES["data-translate-aria-label"] = this.ariaLabel;
      }
      return ATTRIBUTES;
    },

    hasPermissionsForChangeTranslation() {
      return this.checkPermissionsSync({
        perm: [
          "i18n.admin.inline",
          "kataloge.admin.view",
        ],
      });
    },
  },
  created() {
    this.initEventBus();
  },
  beforeUnmount() {
    this.destroyEventBus();
  },
  methods: {
    initEventBus() {
      EventBus.$on("showPlaceholdersTranslate", this.setStatusShowPlaceholder);
    },

    destroyEventBus() {
      EventBus.$off("showPlaceholdersTranslate", this.setStatusShowPlaceholder);
    },

    setStatusShowPlaceholder({ statusShow }) {
      this.statusShowPlaceholder = statusShow;
    },

    getAttributeTranslate({ value, statusTranslate }) {
      if (isNil(value)) {
        return;
      }
      if (!statusTranslate) {
        return replaceText({
          text: value,
          object: this.extra,
        });
      }
      return replaceText({
        text: gettext(value),
        object: this.extra,
      });
    },

    onClick($event) {
      this.$emit("click", $event);
    },
  },
};
