import { toFormElementFromParameter } from "../../../../../global/functions/mappingForParameterToFormElement";

export default {
  data() {
    return {
      jsonParam: {
        bez: "_LBL_GR_WIRKUNGSMONITOR_CONFIG_KONTEXT_",
        default: null,
        help_text: "_TXT_GR_WIRKUNGSMONITOR_CONFIG_KONTEXT_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: true
        },
        typ: "validated_json",
        many: true,
        as_json: true,
        unique_fields: [],
        key_field: "id",
        pos_field: null,
        min_length: null,
        max_length: null,
        element_label: "_LBL_REGEL_DATA_PERIODE_CONFIG_FIELD_ELEMENT_LABEL_",
        element_template: "_LBL_REGEL_DATA_PERIODE_CONFIG_FIELD_ELEMENT_LABEL_{{id}}_{{search}}_{{regelkey}}_{{transform}}_",
        element_errors: true,
        deactivate_ordering: false,
        deactivate_preview: false,
        hide_pos: false,
        optional_single_label: "_LBL_UI_VALIDATED_JSON_SINGLE_TOGGLE_FORM_",
        optional_single_default: false,
        fields: {
          id: {
            prio: 0,
            bez: "Technische ID",
            default: null,
            help_text: "Über diesen Schlüssel werden die Ermittelten Daten bereitgestellt",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: null,
              min_length: null
            },
            typ: "string"
          },
          regelkey: {
            prio: 1,
            bez: "Regelschlüssel",
            default: null,
            help_text: "Die Daten werden aus einer Regel zu dem hier angegebenen Daten am aktuell betrachteten Objekt gesucht",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              displaymode: "select2"
            },
            typ: "single_choice",
            katalog: "regelkeys"
          },
          search: {
            prio: 2,
            bez: "Suchbegriff",
            default: null,
            help_text: "Über diesen Wert werden die gewünschten Daten aus der gefundenen Regel gesucht.",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: null,
              min_length: null
            },
            typ: "string"
          },
          search_periode: {
            prio: 3,
            bez: "_LBL_REGEL_DATA_PERIODE_CONFIG_FIELD_SEARCH_PERIODE_",
            default: null,
            help_text: "_TXT_REGEL_DATA_PERIODE_CONFIG_FIELD_SEARCH_PERIODE_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              max_length: null,
              min_length: null
            },
            typ: "string"
          },
          transform: {
            prio: 4,
            bez: "Ergebnis-Transformation",
            default: "sum",
            help_text: "Abhängig von der betrachteten Regel kann das Ergebnis aus mehreren Einträgen bestehen. Hiermit kann das Ergebnis in eine für die Weiterverarbeitung besser geeignete Form transformiert werden",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "single_choice",
            choices: [
              [
                "sum",
                "Summe"
              ],
              [
                "avg",
                "_LBL_REGEL_VALUE_RESULT_TRANSFORM_CHOICE_AVG_"
              ],
              [
                "min",
                "_LBL_REGEL_VALUE_RESULT_TRANSFORM_CHOICE_MIN_"
              ],
              [
                "max",
                "_LBL_REGEL_VALUE_RESULT_TRANSFORM_CHOICE_MAX_"
              ]
            ]
          },
        },
        prio: 5
      }
    };
  },
  computed: {
    jsonOptions() {
      return toFormElementFromParameter({
        obj: { [`${ this.options.id }`]: this.jsonParam },
        showLabel: true,
        staticOptions: {
          required: this.options.required,
          options: this.options.options
        }
      }).list[0];
    }
  }
};
