import {
  computed,
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import ANotificationAPI from "aloha-vue/src/compositionAPI/ANotificationAPI";
import NotSavedConfirmAPI from "../Confirms/NotSavedConfirmAPI";
import TranslateAPI from "../../../App/compositionAPI/TranslateAPI";

import allLanguages from "aloha-vue/src/i18n/allLanguages";
import {
  forEach,
  map,
} from "lodash-es";

export default function LanguagesAPI({
  allElementsWithTranslation = ref([]),
  countChangedTranslations = computed(() => 0),
  initModel = () => {},
}) {
  const idSelectLang = "lang_select__translate";
  const loadingTranslations = ref(false);
  const modelLanguage = ref("");
  const modelLanguageDefault = ref("");
  const modelLanguageLocal = ref("");
  const translationObj = ref(undefined);

  const {
    postHttp,
  } = AHttpAPI();

  const {
    addNotification,
  } = ANotificationAPI();

  const {
    openNotSavedConfirm,
  } = NotSavedConfirmAPI({
    countChangedTranslations,
  });

  const {
    getAlohaLanguageName,
    languages,
    modelLanguage: modelLanguageGlobal,
  } = TranslateAPI();

  const isModelLanguageDefault = computed(() => {
    return modelLanguage.value === modelLanguageDefault.value;
  });

  const placeholders = computed(() => {
    return map(allElementsWithTranslation.value, el => el.placeholder);
  });

  const setDefaultLanguage = () => {
    translationObj.value = undefined;
  };

  const setTranslationObj = ({ modelLang, response }) => {
    const ALOHA_LANGUAGE_NAME = getAlohaLanguageName(modelLang);
    const ALOHA_TRANSLATIONS_FOR_CURRENT_LANGUAGE = allLanguages[ALOHA_LANGUAGE_NAME];

    const TRANSLATION_OBJ = {
      ...ALOHA_TRANSLATIONS_FOR_CURRENT_LANGUAGE,
    };
    forEach(response, item => {
      TRANSLATION_OBJ[item.ubs_text] = item.ubs_uebersetzung;
    });

    translationObj.value = TRANSLATION_OBJ;
    initModel();
  };

  const loadTranslations = () => {
    if (loadingTranslations.value) {
      return Promise.resolve();
    }

    loadingTranslations.value = true;
    return postHttp({
      url: "uebersetzung/many/",
      data: {
        sprache: modelLanguage.value,
        placeholders: placeholders.value,
      },
    }).then(
      response => {
        setTranslationObj({
          modelLang: modelLanguage.value,
          response,
        });
      },
      () => {
        addNotification({
          text: "_MSG_MODAL_TRANSLATE_CHANGE_LANG_",
          type: "error",
        });
      }
    ).finally(
      () => loadingTranslations.value = false,
    );
  };

  const onChangeLanguage = () => {
    modelLanguage.value = modelLanguageLocal.value;
    if (isModelLanguageDefault.value) {
      setDefaultLanguage();
      initModel({
        withoutLoading: true,
      });
      return;
    }

    loadTranslations().then(
      () => initModel({
        withoutLoading: true,
      })
    );
  };

  const changeLanguage = ({ model: modelLang }) => {
    modelLanguageLocal.value = modelLang;
    openNotSavedConfirm({
      callback: onChangeLanguage,
      ids: idSelectLang,
    });
  };

  const initModelLanguage = () => {
    modelLanguage.value = modelLanguageGlobal.value;
    modelLanguageDefault.value = modelLanguageGlobal.value;
  };

  return {
    changeLanguage,
    idSelectLang,
    initModelLanguage,
    isModelLanguageDefault,
    languages,
    loadingTranslations,
    loadTranslations,
    modelLanguage,
    modelLanguageDefault,
    setDefaultLanguage,
    translationObj,
  };
}
