import {
  computed,
  ref,
} from "vue";

import {
  getTranslatedText,
} from "aloha-vue/src/ATranslation/compositionAPI/UtilsAPI";
import {
  cloneDeep,
  forEach,
} from "lodash-es";

export default function InitModelAPI({
  allElementsWithTranslation = ref([]),
  isModelLanguageDefault = computed(() => false),
  loadTranslations = () => {},
  model = ref({}),
  modelDefault = ref({}),
  translationObj = ref(undefined),
}) {
  const _initModel = () => {
    const MODEL = {};
    forEach(allElementsWithTranslation.value, element => {
      const TEXT = getTranslatedText({
        placeholder: element.placeholder,
        translationObj: translationObj.value,
        alwaysTranslate: true,
      });
      if (TEXT === element.placeholder) {
        MODEL[element.placeholder] = "";
      } else {
        MODEL[element.placeholder] = TEXT;
      }
    });
    model.value = MODEL;
    modelDefault.value = cloneDeep(MODEL);
  };

  const initModel = ({ withoutLoading = false } = {}) => {
    if (isModelLanguageDefault.value || withoutLoading) {
      _initModel();
    } else {
      loadTranslations().then(
        () => _initModel()
      );
    }
  };

  return {
    initModel,
  };
}
