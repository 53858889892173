import {
  computed,
  ref,
} from "vue";

import ANotificationAPI from "aloha-vue/src/compositionAPI/ANotificationAPI";
import NotSavedConfirmAPI from "../Confirms/NotSavedConfirmAPI";

import {
  getUniqueSelector,
} from "aloha-vue/src/utils/utilsDOM";
import {
  getTranslatedText,
} from "aloha-vue/src/ATranslation/compositionAPI/UtilsAPI";
import {
  escape, forEach,
  values,
} from "lodash-es";

export default function DomAPI({
  countChangedTranslations = computed(() => 0),
  initModel = () => {},
  setModalLarge = () => {},
}) {
  const allElementsWithTranslation = ref([]);
  const dataDom = [
    {
      value: "detail",
      label: "_BTN_MODAL_TRANSLATE_INHALTSBEREICH_ANZEIGEN_",
      icon: "layout-detail",
    },
    {
      value: "sidebar",
      label: "_BTN_MODAL_TRANSLATE_NAVIGATIONSBEREICH_ANZEIGEN_",
      icon: "layout-navbar-sidebar",
    },
    {
      value: "all",
      label: "_BTN_MODAL_TRANSLATE_SEITE_ANZEIGEN_",
      icon: "layout-window",
    },
  ];
  const idFindPlaceholders = "find_placeholders_translate";
  const idGetElement = "get_element_translate";
  const idReloadPlaceholders = "reload_placeholders_translate";
  const modelDom = ref("");
  const modelDomLocal = ref("");
  const modelPlaceholders = ref([]);

  const {
    addNotification,
  } = ANotificationAPI();

  const {
    openNotSavedConfirm,
  } = NotSavedConfirmAPI({
    countChangedTranslations,
  });

  const iconGetElement = computed(() => {
    return modelPlaceholders.value.length ?
      "reset" :
      "crosshair";
  });

  const titleGetElement = computed(() => {
    return modelPlaceholders.value.length ?
      "_BTN_MODAL_TRANSLATE_GET_ELEMENT_RESET_TITLE_" :
      "_BTN_MODAL_TRANSLATE_GET_ELEMENT_TITLE_";
  });

  const clearModelPlaceholders = () => {
    modelPlaceholders.value = [];
  };

  const getKeysFromDataTranslateAttr = ({ attrValue }) => {
    return attrValue.trim().split(" ");
  };

  const findAllElementsWithTranslation = () => {
    const ELEMENTS = {};

    function traverse(element) {
      if (!element) {
        return; 
      }

      if (modelDom.value === "detail" && (element.matches("#navbar-fixed-top, #a_menu, #panel_footer__parent, #btn_modal_translate_modal") ||
        element.closest("#navbar-fixed-top, #a_menu, #panel_footer__parent, #btn_modal_translate_modal"))) {
        return;
      }

      if (modelDom.value === "sidebar" && (element.matches("#main_content, #btn_modal_translate_modal") ||
        element.closest("#main_content, #btn_modal_translate_modal"))) {
        return;
      }

      for (const attr of element.attributes) {
        if (attr.name.startsWith("data-translate-")) {
          const PLACEHOLDERS_LOCAL = getKeysFromDataTranslateAttr({ attrValue: attr.value });
          forEach(PLACEHOLDERS_LOCAL, placeholder => {
            if (ELEMENTS[placeholder]) {
              return;
            }

            ELEMENTS[placeholder] = {
              placeholder: placeholder,
              text: escape(getTranslatedText({ placeholder, alwaysTranslate: true })) || "",
              attrName: attr.name,
              tag: element.tagName,
              id: element.id || null,
              class: element.className || null,
              selector: getUniqueSelector({ element }),
            };
          });
        }
      }

      for (const child of element.children) {
        traverse(child);
      }
    }

    traverse(document.body);
    allElementsWithTranslation.value = values(ELEMENTS);
    initModel({ elements: allElementsWithTranslation.value });
    clearModelPlaceholders();
  };

  const activateModeFindElementWithTranslation = () => {
    modelDom.value = undefined;
    document.body.classList.add("body_cursor_crosshair");
    document.addEventListener("click", clickInBody, true);
  };

  const deactivateModeFindElementWithTranslation = () => {
    document.body.classList.remove("body_cursor_crosshair");
    document.removeEventListener("click", clickInBody, true);
  };

  const setModelDomDefault = () => {
    modelDom.value = "detail";
    findAllElementsWithTranslation();
  };

  const cancelModeFindElementWithTranslation = () => {
    clearModelPlaceholders();
    setModelDomDefault();
  };

  const toggleModeFindElementWithTranslation = () => {
    if (modelPlaceholders.value.length) {
      openNotSavedConfirm({
        callback: cancelModeFindElementWithTranslation,
        ids: idGetElement,
      });
    } else {
      openNotSavedConfirm({
        callback: activateModeFindElementWithTranslation,
        ids: idGetElement,
      });
    }
  };

  function clickInBody(event) {
    event.stopPropagation();
    event.preventDefault();
    deactivateModeFindElementWithTranslation();

    let element = event.target;
    const ELEMENTS = {};
    const PLACEHOLDERS = [];

    while (element) {
      for (const attr of element.attributes) {
        if (attr.name.startsWith("data-translate-")) {
          const PLACEHOLDERS_LOCAL = getKeysFromDataTranslateAttr({ attrValue: attr.value });
          // eslint-disable-next-line no-loop-func
          forEach(PLACEHOLDERS_LOCAL, placeholder => {
            if (ELEMENTS[placeholder]) {
              return;
            }

            ELEMENTS[placeholder] = {
              placeholder: placeholder,
              text: escape(getTranslatedText({ placeholder, alwaysTranslate: true })) || "",
              attrName: attr.name,
              tag: element.tagName,
              id: element.id || null,
              class: element.className || null,
              selector: getUniqueSelector({ element }),
            };
            PLACEHOLDERS.push(placeholder);
          });
        }
      }

      element = element.parentElement;
    }

    modelPlaceholders.value = PLACEHOLDERS;
    allElementsWithTranslation.value = values(ELEMENTS);
    initModel({ elements: allElementsWithTranslation.value });
    if (modelPlaceholders.value.length) {
      addNotification({
        text: "_MSG_MODAL_TRANSLATE_FOUND_TRANSLATIONS_{{count}}_",
        extra: {
          count: modelPlaceholders.value.length,
        },
      });
      setModalLarge();
    } else {
      addNotification({
        text: "_MSG_MODAL_TRANSLATE_NOT_FOUND_TRANSLATIONS_",
        type: "warning",
      });
    }
  }

  const onChangeModelDom = () => {
    modelDom.value = modelDomLocal.value;
    findAllElementsWithTranslation();
  };

  const changeModelDom = model => {
    modelDomLocal.value = model;

    openNotSavedConfirm({
      callback: onChangeModelDom,
      ids: idFindPlaceholders,
    });
  };

  const reloadFindAllElementsWithTranslation = () => {
    openNotSavedConfirm({
      callback: findAllElementsWithTranslation,
      ids: idReloadPlaceholders,
    });
  };

  return {
    allElementsWithTranslation,
    changeModelDom,
    clearModelPlaceholders,
    dataDom,
    deactivateModeFindElementWithTranslation,
    iconGetElement,
    idFindPlaceholders,
    idGetElement,
    idReloadPlaceholders,
    modelDom,
    modelPlaceholders,
    reloadFindAllElementsWithTranslation,
    setModelDomDefault,
    titleGetElement,
    toggleModeFindElementWithTranslation,
  };
}
