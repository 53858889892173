import {
  isPlainObject,
  isUndefined,
} from "lodash-es";
import {
  gettext,
  replaceText,
  isTranslatePlaceholder,
} from "../functions/utils";
import EventBus from "aloha-vue/src/utils/EventBus";

function setTranslate({ el, value, statusShow }) {
  if (!isPlainObject(value)) {
    return;
  }
  const NOT_TRANSLATE = isUndefined(statusShow) ? window.statusShowPlaceholdersTranslate : statusShow;
  const TEXT = value.text;
  const HTML = value.html;
  const TITLE = value.title;
  const PLACEHOLDER = value.placeholder;
  const ARIA_LABEL = value["aria-label"];

  if (TEXT) {
    const IS_TRANSLATED_TEXT = isTranslatePlaceholder(TEXT);
    const NOT_TRANSLATE_TEXT = NOT_TRANSLATE || !IS_TRANSLATED_TEXT;
    el.textContent = NOT_TRANSLATE_TEXT ? TEXT : replaceText({ text: gettext(TEXT), object: value.extra });
    if (IS_TRANSLATED_TEXT) {
      el.setAttribute("data-translate-text", TEXT);
    }
  }
  if (HTML) {
    const IS_TRANSLATED_HTML = isTranslatePlaceholder(HTML);
    const NOT_TRANSLATE_HTML = NOT_TRANSLATE || !IS_TRANSLATED_HTML;
    el.innerHTML = NOT_TRANSLATE_HTML ? HTML : replaceText({ text: gettext(HTML), object: value.extra });
    if (IS_TRANSLATED_HTML) {
      el.setAttribute("data-translate-html", HTML);
    }
  }
  if (TITLE) {
    const IS_TRANSLATED_TITLE = isTranslatePlaceholder(TITLE);
    const NOT_TRANSLATE_TITLE = NOT_TRANSLATE || !IS_TRANSLATED_TITLE;
    el.setAttribute("title", NOT_TRANSLATE_TITLE ? TITLE : replaceText({ text: gettext(TITLE), object: value.extra }));
    if (IS_TRANSLATED_TITLE) {
      el.setAttribute(`data-translate-title`, TITLE);
    }
  }
  if (PLACEHOLDER) {
    const IS_TRANSLATED_PLACEHOLDER = isTranslatePlaceholder(PLACEHOLDER);
    const NOT_TRANSLATE_PLACEHOLDER = NOT_TRANSLATE || !IS_TRANSLATED_PLACEHOLDER;
    el.setAttribute("placeholder", NOT_TRANSLATE_PLACEHOLDER ? PLACEHOLDER : replaceText({ text: gettext(PLACEHOLDER), object: value.extra }));
    if (IS_TRANSLATED_PLACEHOLDER) {
      el.setAttribute(`data-translate-placeholder`, PLACEHOLDER);
    }
  }
  if (ARIA_LABEL) {
    const IS_TRANSLATED_ARIA_LABEL = isTranslatePlaceholder(ARIA_LABEL);
    const NOT_TRANSLATE_ARIA_LABEL = NOT_TRANSLATE || !IS_TRANSLATED_ARIA_LABEL;
    el.setAttribute("aria-label", NOT_TRANSLATE_ARIA_LABEL ? ARIA_LABEL : replaceText({ text: gettext(ARIA_LABEL), object: value.extra }));
    if (IS_TRANSLATED_ARIA_LABEL) {
      el.setAttribute(`data-translate-aria-label`, ARIA_LABEL);
    }
  }
}


export default {
  beforeMount(el, binding) {
    EventBus.$on("showPlaceholdersTranslate", ({ statusShow }) => setTranslate({ el, value: binding.value, statusShow }));
    setTranslate({ el, value: binding.value });
  },
  updated(el, binding) {
    setTranslate({ el, value: binding.value });
  },
};
