import UiAdressen from "./Adressen/UiAdressen/UiAdressen.vue";
import UiAmpel from "./Ampel/UiAmpel/UiAmpel.vue";
import UiAntragBudgetkontoDimensionen from "./AntragBudgetkonto/UiAntragBudgetkontoDimensionen/UiAntragBudgetkontoDimensionen.vue";
import UiAntragBudgetkontoKonto from "./AntragBudgetkonto/UiAntragBudgetkontoKonto/UiAntragBudgetkontoKonto.vue";
import UiAntragScore from "./AntragScore/UiAntragScore/UiAntragScore.vue";
import UiAntragstellerRechtsformZusatz from "./AntragstellerRechtsformZusatz/UiAntragstellerRechtsformZusatz/UiAntragstellerRechtsformZusatz.vue";
import UiAnzahlBetreuerFerien from "./AnzahlBetreuerFerien/UiAnzahlBetreuerFerien/UiAnzahlBetreuerFerien.vue";
import UiAuswahlKatalog from "./AuswahlKatalog/UiAuswahlKatalog/UiAuswahlKatalog.vue";
import UiAuszahlungsmodi from "./Auszahlungsmodi/UiAuszahlungsmodi/UiAuszahlungsmodi.vue";
import UiAuszahlungModalitaet from "./AuszahlungModalitaet/UiAuszahlungModalitaet/UiAuszahlungModalitaet.vue";
import UiBestandZeilenFelder from "./VertragBestand/UiBestandZeilenFelder/UiBestandZeilenFelder.vue";
import UiDynamischeAdressen from "./DynamischeAdressen/UiDynamischeAdressen/UiDynamischeAdressen.vue";
import UiDynamischeMehrfachauswahl from "./DynamischeMehrfachauswahl/UiDynamischeMehrfachauswahl/UiDynamischeMehrfachauswahl.vue";
import UiDynamischeTabelle from "./DynamischeTabelle/UiDynamischeTabelle/UiDynamischeTabelle.vue";
import UiDynamischeTabelleObjektkatalogData from "./DynamischeTabelle/UiDynamischeTabelleObjektkatalogData/UiDynamischeTabelleObjektkatalogData.vue";
import UiDynamischeTabellePdfTranspose from "./DynamischeTabelle/UiDynamischeTabellePdfTranspose/UiDynamischeTabellePdfTranspose.vue";
import UiDynamischeTabellePdfTransposeReadOnly from "./DynamischeTabelle/UiDynamischeTabellePdfTranspose/UiDynamischeTabellePdfTransposeReadOnly/UiDynamischeTabellePdfTransposeReadOnly.vue";
import UiDynamischeTabellePerioden from "./DynamischeTabellePerioden/UiDynamischeTabellePerioden/UiDynamischeTabellePerioden.vue";
import UiDynamischeTabelleSpaltenAuswahlRadio from "./DynamischeTabelle/UiDynamischeTabelleObjektkatalogData/UiDynamischeTabelleSpaltenAuswahlRadio/UiDynamischeTabelleSpaltenAuswahlRadio.vue";
import UiDynamischeTabelleSpaltenAuswahlRadioReadOnly from "./DynamischeTabelle/UiDynamischeTabelleObjektkatalogData/UiDynamischeTabelleSpaltenAuswahlRadio/UiDynamischeTabelleSpaltenAuswahlRadioReadOnly/UiDynamischeTabelleSpaltenAuswahlRadioReadOnly.vue";
import UiDynamischeZahlen from "./DynamischeZahlen/UiDynamischeZahlen/UiDynamischeZahlen.vue";
import UiElementliste from "./Elementliste/UiElementliste/UiElementliste.vue";
import UiElementverzweigung from "./Elementverzweigung/UiElementverzweigung/UiElementverzweigung.vue";
import UiGutscheineErfassen from "./GutscheineErfassen/UiGutscheineErfassen/UiGutscheineErfassen.vue";
import UiGutscheineErfassenReadOnly from "./GutscheineErfassen/UiGutscheineErfassen/UiGutscheineErfassenReadOnly/UiGutscheineErfassenReadOnly.vue";
import UiProjektberichte from "./Projektberichte/UiProjektberichte/UiProjektberichte.vue";
import UiThemenauswahl from "./Themenauswahl/UiThemenauswahl/UiThemenauswahl.vue";
import UiWmKennzahlenConfig from "./Wirkungsmonitor/UiWmKennzahlenConfig/UiWmKennzahlenConfig.vue";
import UiWmRegelDataConfig from "./Wirkungsmonitor/UiWmRegelDataConfig/UiWmRegelDataConfig.vue";
import UiZahlungsverkehr from "./Zahlungsverkehr/UiZahlungsverkehr/UiZahlungsverkehr.vue";
import UiZielgruppenAuswahl from "./ZielgruppenAuswahl/UiZielgruppenAuswahl/UiZielgruppenAuswahl.vue";

// DHW
import UiZweckbindung from "./DHW/Zweckbindung/UiZweckbindung/UiZweckbindung.vue";
import UiAbschreibungsraten from "./DHW/Zweckbindung/UiAbschreibungsraten/UiAbschreibungsraten.vue";
// DHW /

// // ReadOnly
import UiAdressenReadOnly from "./Adressen/UiAdressen/UiAdressenReadOnly/UiAdressenReadOnly.vue";
import UiAmpelReadOnly from "./Ampel/UiAmpel/UiAmpelReadOnly/UiAmpelReadOnly.vue";
import UiAntragBudgetkontoDimensionenReadOnly from "./AntragBudgetkonto/UiAntragBudgetkontoDimensionen/UiAntragBudgetkontoDimensionenReadOnly/UiAntragBudgetkontoDimensionenReadOnly.vue";
import UiAntragBudgetkontoKontoReadOnly from "./AntragBudgetkonto/UiAntragBudgetkontoKonto/UiAntragBudgetkontoKontoReadOnly/UiAntragBudgetkontoKontoReadOnly.vue";
import UiAntragScoreReadOnly from "./AntragScore/UiAntragScore/UiAntragScoreReadOnly/UiAntragScoreReadOnly.vue";
import UiAntragstellerRechtsformZusatzReadOnly from "./AntragstellerRechtsformZusatz/UiAntragstellerRechtsformZusatz/UiAntragstellerRechtsformZusatzReadOnly/UiAntragstellerRechtsformZusatzReadOnly.vue";
import UiAnzahlBetreuerFerienReadOnly
  from "./AnzahlBetreuerFerien/UiAnzahlBetreuerFerien/UiAnzahlBetreuerFerienReadOnly/UiAnzahlBetreuerFerienReadOnly.vue";
import UiAuswahlKatalogReadOnly
  from "./AuswahlKatalog/UiAuswahlKatalog/UiAuswahlKatalogReadOnly/UiAuswahlKatalogReadOnly.vue";
import UiAuszahlungsmodiReadOnly
  from "./Auszahlungsmodi/UiAuszahlungsmodi/UiAuszahlungsmodiReadOnly/UiAuszahlungsmodiReadOnly.vue";
import UiAuszahlungModalitaetReadOnly from "./AuszahlungModalitaet/UiAuszahlungModalitaet/UiAuszahlungModalitaetReadOnly/UiAuszahlungModalitaetReadOnly.vue";
import UiBestandZeilenFelderReadOnly from "./VertragBestand/UiBestandZeilenFelder/UiBestandZeilenFelderReadOnly/UiBestandZeilenFelderReadOnly.vue";
import UiDynamischeAdressenReadOnly
  from "./DynamischeAdressen/UiDynamischeAdressen/UiDynamischeAdressenReadOnly/UiDynamischeAdressenReadOnly.vue";
import UiDynamischeMehrfachauswahlReadOnly
  from "./DynamischeMehrfachauswahl/UiDynamischeMehrfachauswahl/UiDynamischeMehrfachauswahlReadOnly/UiDynamischeMehrfachauswahlReadOnly.vue";
import UiDynamischeTabelleReadOnly
  from "./DynamischeTabelle/UiDynamischeTabelle/UiDynamischeTabelleReadOnly/UiDynamischeTabelleReadOnly.vue";
import UiDynamischeTabelleObjektkatalogDataReadOnly
  from "./DynamischeTabelle/UiDynamischeTabelleObjektkatalogData/UiDynamischeTabelleObjektkatalogDataReadOnly/UiDynamischeTabelleObjektkatalogDataReadOnly.vue";
import UiDynamischeTabellePeriodenReadOnly
  from "./DynamischeTabellePerioden/UiDynamischeTabellePerioden/UiDynamischeTabellePeriodenReadOnly/UiDynamischeTabellePeriodenReadOnly.vue";
import UiDynamischeZahlenReadOnly
  from "./DynamischeZahlen/UiDynamischeZahlen/UiDynamischeZahlenReadOnly/UiDynamischeZahlenReadOnly.vue";
import UiElementlisteReadOnly from "./Elementliste/UiElementliste/UiElementlisteReadOnly/UiElementlisteReadOnly.vue";
import UiElementverzweigungReadOnly
  from "./Elementverzweigung/UiElementverzweigung/UiElementverzweigungReadOnly/UiElementverzweigungReadOnly.vue";
import UiProjektberichteReadOnly
  from "./Projektberichte/UiProjektberichte/UiProjektberichteReadOnly/UiProjektberichteReadOnly.vue";
import UiThemenauswahlReadOnly from "./Themenauswahl/UiThemenauswahl/UiThemenauswahlReadOnly/UiThemenauswahlReadOnly.vue";
import UiWmKennzahlenConfigReadOnly from "./Wirkungsmonitor/UiWmKennzahlenConfig/UiWmKennzahlenConfigReadOnly/UiWmKennzahlenConfigReadOnly.vue";
import UiWmRegelDataConfigReadOnly from "./Wirkungsmonitor/UiWmRegelDataConfig/UiWmRegelDataConfigReadOnly/UiWmRegelDataConfigReadOnly.vue";
import UiZahlungsverkehrReadOnly from "./Zahlungsverkehr/UiZahlungsverkehr/UiZahlungsverkehrReadOnly/UiZahlungsverkehrReadOnly.vue";
import UiZielgruppenAuswahlReadOnly
  from "./ZielgruppenAuswahl/UiZielgruppenAuswahl/UiZielgruppenAuswahlReadOnly/UiZielgruppenAuswahlReadOnly.vue";

// DHW
import UiZweckbindungReadOnly from "./DHW/Zweckbindung/UiZweckbindung/UiZweckbindungReadOnly/UiZweckbindungReadOnly.vue";
import UiAbschreibungsratenReadOnly from "./DHW/Zweckbindung/UiAbschreibungsraten/UiAbschreibungsratenReadOnly/UiAbschreibungsratenReadOnly.vue";
// DHW /

export default {
  UiAdressen,
  UiAmpel,
  UiAntragScore,
  UiAntragBudgetkontoDimensionen,
  UiAntragBudgetkontoKonto,
  UiAntragstellerRechtsformZusatz,
  UiAnzahlBetreuerFerien,
  UiAuswahlKatalog,
  UiAuszahlungsmodi,
  UiAuszahlungModalitaet,
  UiBestandZeilenFelder,
  UiDynamischeAdressen,
  UiDynamischeMehrfachauswahl,
  UiDynamischeTabelle,
  UiDynamischeTabelleObjektkatalogData,
  UiDynamischeTabellePdfTranspose,
  UiDynamischeTabellePerioden,
  UiDynamischeTabelleSpaltenAuswahlRadio,
  UiDynamischeZahlen,
  UiElementliste,
  UiElementverzweigung,
  UiGutscheineErfassen,
  UiProjektberichte,
  UiThemenauswahl,
  UiWmKennzahlenConfig,
  UiWmRegelDataConfig,
  UiZahlungsverkehr,
  UiZielgruppenAuswahl,
  // DHW
  UiZweckbindung,
  UiAbschreibungsraten,
  // DHW /
};

export const READ_ONLY = {
  UiAdressenReadOnly,
  UiAmpelReadOnly,
  UiAntragBudgetkontoDimensionenReadOnly,
  UiAntragBudgetkontoKontoReadOnly,
  UiAntragScoreReadOnly,
  UiAntragstellerRechtsformZusatzReadOnly,
  UiAnzahlBetreuerFerienReadOnly,
  UiAuswahlKatalogReadOnly,
  UiAuszahlungsmodiReadOnly,
  UiAuszahlungModalitaetReadOnly,
  UiBestandZeilenFelderReadOnly,
  UiDynamischeAdressenReadOnly,
  UiDynamischeMehrfachauswahlReadOnly,
  UiDynamischeTabelleReadOnly,
  UiDynamischeTabelleObjektkatalogDataReadOnly,
  UiDynamischeTabellePdfTransposeReadOnly,
  UiDynamischeTabellePeriodenReadOnly,
  UiDynamischeTabelleSpaltenAuswahlRadioReadOnly,
  UiDynamischeZahlenReadOnly,
  UiElementlisteReadOnly,
  UiElementverzweigungReadOnly,
  UiGutscheineErfassenReadOnly,
  UiProjektberichteReadOnly,
  UiThemenauswahlReadOnly,
  UiWmKennzahlenConfigReadOnly,
  UiWmRegelDataConfigReadOnly,
  UiZahlungsverkehrReadOnly,
  UiZielgruppenAuswahlReadOnly,
  // DHW
  UiZweckbindungReadOnly,
  UiAbschreibungsratenReadOnly,
  // DHW /
};

export const MAPPING = {
  adressen_felder: "UiAdressen",
  ampel: "UiAmpel",
  antrag_budgetkonto_dimensionen: "UiAntragBudgetkontoDimensionen",
  antrag_budgetkonto_konto: "UiAntragBudgetkontoKonto",
  antrag_score_config: "UiAntragScore",
  antragsteller_rechtsform_zusatz_zweige: "UiAntragstellerRechtsformZusatz",
  anzahl_betreuer_ferien: "UiAnzahlBetreuerFerien",
  auswahl_katalog_eintraege: "UiAuswahlKatalog",
  auswahl_katalog_vorbelegung_zufaellig: "UiBoolean",
  auszahlungsmodi: "UiAuszahlungsmodi",
  auszahlung_modalitaet: "UiAuszahlungModalitaet",
  bestand_zeilen: "UiBestandZeilenFelder",
  dynamische_adressen: "UiDynamischeAdressen",
  dynamische_mehrfachauswahl: "UiDynamischeMehrfachauswahl",
  dynamische_tabelle: "UiDynamischeTabelle",
  dyn_tabelle_objektkatalog_data: "UiDynamischeTabelleObjektkatalogData",
  dyn_tabelle_pdf_transpose: "UiDynamischeTabellePdfTranspose",
  dyn_tabelle_spaltenauswahl_radio: "UiDynamischeTabelleSpaltenAuswahlRadio",
  dynamische_zahlen: "UiDynamischeZahlen",
  elementliste_regeldata: "UiJson",
  elementliste: "UiElementliste",
  elementverzweigung: "UiElementverzweigung",
  gutscheine_erfassen_spalten: "UiGutscheineErfassen",
  kennzahlen_kontext: "UiJson",
  kennzahlen_berechnung: "UiJson",
  perioden_spalten: "UiDynamischeTabellePerioden",
  projektberichte: "UiProjektberichte",
  signal_kosten_regeldata: "UiJson",
  signal_pauschale_config_json: "UiJson",
  themen_auswahl: "UiThemenauswahl",
  wm_kennzahlenconfig: "UiWmKennzahlenConfig",
  wm_regeldataconfig: "UiWmRegelDataConfig",
  zahlungsverkehr_felder: "UiZahlungsverkehr",
  zielgruppen_auswahl: "UiZielgruppenAuswahl",
  // DHW
  dhw_abschreibungsraten: "UiAbschreibungsraten",
  dhw_bewilligungszeitraum_abschreibungsraten: "UiZweckbindung",
  dhw_zuschuss_extra_validierung_regeln: "UiJson",
  // DHW /
};

export const MAPPING_READONLY = {
  adressen_felder: "UiAdressenReadOnly",
  ampel: "UiAmpelReadOnly",
  antrag_budgetkonto_dimensionen: "UiAntragBudgetkontoDimensionenReadOnly",
  antrag_budgetkonto_konto: "UiAntragBudgetkontoKontoReadOnly",
  antrag_score_config: "UiAntragScoreReadOnly",
  antragsteller_rechtsform_zusatz_zweige: "UiAntragstellerRechtsformZusatzReadOnly",
  anzahl_betreuer_ferien: "UiAnzahlBetreuerFerienReadOnly",
  auswahl_katalog_eintraege: "UiAuswahlKatalogReadOnly",
  auswahl_katalog_vorbelegung_zufaellig: "UiBooleanReadOnly",
  auszahlungsmodi: "UiAuszahlungsmodiReadOnly",
  auszahlung_modalitaet: "UiAuszahlungModalitaetReadOnly",
  bestand_zeilen: "UiBestandZeilenFelderReadOnly",
  dynamische_adressen: "UiDynamischeAdressenReadOnly",
  dynamische_mehrfachauswahl: "UiDynamischeMehrfachauswahlReadOnly",
  dynamische_tabelle: "UiDynamischeTabelleReadOnly",
  dyn_tabelle_objektkatalog_data: "UiDynamischeTabelleObjektkatalogDataReadOnly",
  dyn_tabelle_pdf_transpose: "UiDynamischeTabellePdfTransposeReadOnly",
  dyn_tabelle_spaltenauswahl_radio: "UiDynamischeTabelleSpaltenAuswahlRadioReadOnly",
  dynamische_zahlen: "UiDynamischeZahlenReadOnly",
  elementliste_regeldata: "UiJsonReadOnly",
  elementliste: "UiElementlisteReadOnly",
  elementverzweigung: "UiElementverzweigungReadOnly",
  gutscheine_erfassen_spalten: "UiGutscheineErfassenReadOnly",
  kennzahlen_kontext: "UiJsonReadOnly",
  kennzahlen_berechnung: "UiJsonReadOnly",
  perioden_spalten: "UiDynamischeTabellePeriodenReadOnly",
  projektberichte: "UiProjektberichteReadOnly",
  signal_kosten_regeldata: "UiJsonReadOnly",
  signal_pauschale_config_json: "UiJsonReadOnly",
  themen_auswahl: "UiThemenauswahlReadOnly",
  wm_kennzahlenconfig: "UiWmKennzahlenConfigReadOnly",
  wm_regeldataconfig: "UiWmRegelDataConfigReadOnly",
  zahlungsverkehr_felder: "UiZahlungsverkehrReadOnly",
  zielgruppen_auswahl: "UiZielgruppenAuswahlReadOnly",
  // DHW
  dhw_bewilligungszeitraum_abschreibungsraten: "UiZweckbindungReadOnly",
  dhw_abschreibungsraten: "UiAbschreibungsratenReadOnly",
  dhw_zuschuss_extra_validierung_regeln: "UiJsonReadOnly",
  // DHW /
};

export const MODEL = {
  adressen_felder: () => ({}),
  ampel: () => ({
    rot: null,
    gelb: null,
    gruen: null,
  }),
  antrag_score_config: () => ({}),
  anzahl_betreuer_ferien: () => [
    {
      pos: "teilnehmer",
      label: null,
      value: null,
      min: null,
      max: null,
      nachkommastellen: null,
      einheit: null,
      hilfetext: null,
      placeholder: null,
    },
    {
      pos: "teilnehmer_mit_behinderung",
      label: null,
      value: null,
      min: null,
      max: null,
      nachkommastellen: null,
      einheit: null,
      hilfetext: null,
      placeholder: null,
    },
    {
      pos: "betreuer",
      label: null,
      value: null,
      min: null,
      max: null,
      nachkommastellen: null,
      einheit: null,
      hilfetext: null,
      placeholder: null,
    },
  ],
  auswahl_katalog_eintraege: () => [],
  antragsteller_rechtsform_zusatz_zweige: () => [],
  projektberichte: () => [],
  auszahlungsmodi: () => [],
  auszahlung_modalitaet: () => ({}),
  dynamische_adressen: () => [],
  dynamische_mehrfachauswahl: () => [],
  dynamische_tabelle: () => ({}),
  dyn_tabelle_objektkatalog_data: () => [],
  dynamische_zahlen: () => [],
  elementliste: () => [],
  elementverzweigung: () => [],
  gutscheine_erfassen_spalten: () => ({}),
  perioden_spalten: () => ({}),
  themen_auswahl: () => [],
  zahlungsverkehr_felder: () => ({}),
  zielgruppen_auswahl: () => [],
  // DHW
  dhw_abschreibungsraten: () => [],
  dhw_bewilligungszeitraum_abschreibungsraten: () => [],
  // DHW /
};

export const EXTRA_OPTIONS_FOR_TYPE = {
  pruefung_betreuungsverhaeltnis: {
    hideErrors: true,
  },
  anzahl_betreuer_ferien: {
    hideErrors: true,
  },
  auszahlungsmodi: {
    hideErrors: true,
  },
};
