import {
  computed,
} from "vue";

import TranslateAPI from "../../../App/compositionAPI/TranslateAPI";

export default function NavbarTranslateAPI() {
  const {
    changeLanguage,
    languages,
    modelLanguage,
  } = TranslateAPI();

  const moreThanOneLanguages = computed(() => {
    return languages.value.length > 1;
  });

  return {
    changeLanguage,
    languages,
    modelLanguage,
    moreThanOneLanguages,
  };
}
