const WirkungsmonitorDetails = () => import(/* webpackChunkName: "WirkungsmonitorDetails" */ "../../views/Wirkungsmonitor/WirkungsmonitorDetails/WirkungsmonitorDetails.vue");

export default [
  {
    path: "/wirkungsmonitor/",
    name: "root.wirkungsmonitor",
    component: WirkungsmonitorDetails,
    meta: {
      permissions: [
        "wirkungsmonitor.ui",
      ],
    },
  },
];
