import {
  computed,
  ref,
} from "vue";

export default function SizesAPI() {
  const isModalSmall = ref(false);

  const dropdownClass = computed(() => {
    return isModalSmall.value ?
      "a_filter_horizontal__wrapper a_filter_horizontal__wrapper_small" :
      "a_filter_horizontal__wrapper a_filter_horizontal__wrapper_translate";
  });

  const iconModalSizes = computed(() => {
    return isModalSmall.value ?
      "arrows-angle-expand" :
      "arrows-angle-contract";
  });

  const titleModalSizes = computed(() => {
    return isModalSmall.value ?
      "_BTN_MODAL_TRANSLATE_FIND_EXPAND_TITLE_" :
      "_BTN_MODAL_TRANSLATE_FIND_CONTRACT_TITLE_";
  });

  const setModalSmall = () => {
    isModalSmall.value = true;
  };

  const setModalLarge = () => {
    isModalSmall.value = false;
  };

  const toggleModalSize = () => {
    if (isModalSmall.value) {
      setModalLarge();
    } else {
      setModalSmall();
    }
  };

  return {
    dropdownClass,
    iconModalSizes,
    setModalLarge,
    toggleModalSize,
    titleModalSizes,
  };
}
