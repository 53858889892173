import ADropdown from "aloha-vue/src/ADropdown/ADropdown";
import AElement from "aloha-vue/src/AElement/AElement";
import AFieldset from "aloha-vue/src/ui/AFieldset/AFieldset";
import AInput from "aloha-vue/src/ui/AInput/AInput";
import ALoading from "aloha-vue/src/ALoading/ALoading";
import ARadio from "aloha-vue/src/ui/ARadio/ARadio";
import ASelect from "aloha-vue/src/ui/ASelect/ASelect";
import ATranslation from "aloha-vue/src/ATranslation/ATranslation";
import TheModalTranslateInput from "./TheModalTranslateInput/TheModalTranslateInput.vue";

import DomAPI from "./compositionAPI/DomAPI";
import InitModelAPI from "./compositionAPI/InitModelAPI";
import LanguagesAPI from "./compositionAPI/LanguagesAPI";
import ModalAPI from "./compositionAPI/ModalAPI";
import ModelAPI from "./compositionAPI/ModelAPI";
import PermissionGlobalAPI from "../../global/compositionAPI/PermissionGlobalAPI";
import SaveAPI from "./compositionAPI/SaveAPI";
import SearchAPI from "./compositionAPI/SearchAPI";
import SizesAPI from "./compositionAPI/SizesAPI";
import TranslatePlaceholdersAPI from "./compositionAPI/TranslatePlaceholdersAPI";

// @vue/component
export default {
  name: "TheModalTranslate",
  components: {
    ADropdown,
    AElement,
    AFieldset,
    AInput,
    ALoading,
    ARadio,
    ASelect,
    ATranslation,
    TheModalTranslateInput,
  },
  setup() {
    const {
      hasPermission: hasPermissionI18nAdminInline,
    } = PermissionGlobalAPI({
      permission: "i18n.admin.inline",
    });

    const {
      clearModel,
      countChangedTranslations,
      getModelForSave,
      model,
      modelChanged,
      modelDefault,
      resetModelDefault,
    } = ModelAPI();

    const {
      dropdownClass,
      iconModalSizes,
      setModalLarge,
      toggleModalSize,
      titleModalSizes,
    } = SizesAPI();

    const {
      dataPlaceholders,
      hidePlaceholdersTranslate,
      isPlaceholdersTranslateVisible,
      togglePlaceholdersTranslate,
    } = TranslatePlaceholdersAPI();

    const {
      allElementsWithTranslation,
      changeModelDom,
      clearModelPlaceholders,
      dataDom,
      deactivateModeFindElementWithTranslation,
      iconGetElement,
      idFindPlaceholders,
      idGetElement,
      idReloadPlaceholders,
      modelDom,
      modelPlaceholders,
      reloadFindAllElementsWithTranslation,
      setModelDomDefault,
      titleGetElement,
      toggleModeFindElementWithTranslation,
    } = DomAPI({
      countChangedTranslations,
      initModel,
      setModalLarge,
    });

    const {
      changeLanguage,
      idSelectLang,
      initModelLanguage,
      isModelLanguageDefault,
      languages,
      loadingTranslations,
      loadTranslations,
      modelLanguage,
      modelLanguageDefault,
      setDefaultLanguage,
      translationObj,
    } = LanguagesAPI({
      allElementsWithTranslation,
      countChangedTranslations,
      initModel,
    });

    const {
      initModel: _initModel,
    } = InitModelAPI({
      allElementsWithTranslation,
      isModelLanguageDefault,
      loadTranslations,
      model,
      modelDefault,
      translationObj,
    });

    const {
      changeModelSearch,
      initModelSearch,
      isAnyElementFound,
      modelSearch,
      modelSearchLocal,
      searchHideElements,
    } = SearchAPI({
      allElementsWithTranslation,
    });

    const {
      closeDropdown,
      closeModal,
      dropdownRef,
      idClose,
      openModal,
    } = ModalAPI({
      clearModel,
      clearModelPlaceholders,
      countChangedTranslations,
      deactivateModeFindElementWithTranslation,
      hidePlaceholdersTranslate,
      initModelLanguage,
      initModelSearch,
      setDefaultLanguage,
      setModalLarge,
      setModelDomDefault,
    });

    const {
      save,
      saving,
    } = SaveAPI({
      countChangedTranslations,
      getModelForSave,
      modelLanguage,
      modelLanguageDefault,
      resetModelDefault,
    });

    function initModel(arg) {
      _initModel(arg);
    }

    return {
      allElementsWithTranslation,
      changeLanguage,
      changeModelDom,
      changeModelSearch,
      closeDropdown,
      closeModal,
      countChangedTranslations,
      dataDom,
      dataPlaceholders,
      dropdownClass,
      dropdownRef,
      hasPermissionI18nAdminInline,
      iconGetElement,
      iconModalSizes,
      idClose,
      idFindPlaceholders,
      idGetElement,
      idReloadPlaceholders,
      idSelectLang,
      isAnyElementFound,
      isPlaceholdersTranslateVisible,
      languages,
      loadingTranslations,
      model,
      modelChanged,
      modelDefault,
      modelDom,
      modelLanguage,
      modelPlaceholders,
      modelSearch,
      modelSearchLocal,
      openModal,
      reloadFindAllElementsWithTranslation,
      save,
      saving,
      searchHideElements,
      titleGetElement,
      titleModalSizes,
      toggleModalSize,
      toggleModeFindElementWithTranslation,
      togglePlaceholdersTranslate,
    };
  },
};
