import {
  onBeforeUnmount,
} from "vue";

import AElement from "aloha-vue/src/AElement/AElement";
import AInput from "aloha-vue/src/ui/AInput/AInput";
import ATextarea from "aloha-vue/src/ui/ATextarea/ATextarea";

import DomAPI from "./compositionAPI/DomAPI";
import HighlightAPI from "./compositionAPI/HighlightAPI";
import ModelAPI from "./compositionAPI/ModelAPI";

// @vue/component
export default {
  name: "TheModalTranslateInput",
  components: {
    AElement,
    AInput,
    ATextarea,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    hasChanges: {
      type: Boolean,
      required: false,
    },
    isHide: {
      type: Boolean,
      required: false,
    },
    modelSearch: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: [
    "update:modelValue",
  ],
  setup(props, context) {
    const {
      placeholderWithSearch,
      textWithSearch,
    } = HighlightAPI(props);

    const {
      destroyEventBus,
      hideHighlightInDom,
      iconForHighlight,
      initEventBus,
      titleForHighlight,
      toggleHighlightInDom,
    } = DomAPI(props);

    const {
      updateModel,
    } = ModelAPI(props, context);

    initEventBus();

    onBeforeUnmount(() => {
      hideHighlightInDom();
      destroyEventBus();
    });

    return {
      iconForHighlight,
      placeholderWithSearch,
      textWithSearch,
      titleForHighlight,
      toggleHighlightInDom,
      updateModel,
    };
  },
};
