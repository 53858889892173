import {
  computed,
  ref,
} from "vue";

import NotSavedConfirmAPI from "../Confirms/NotSavedConfirmAPI";

export default function ModalAPI({
  clearModel = () => {},
  clearModelPlaceholders = () => {},
  countChangedTranslations = computed(() => 0),
  deactivateModeFindElementWithTranslation = () => {},
  hidePlaceholdersTranslate = () => {},
  initModelLanguage = () => {},
  initModelSearch = () => {},
  setDefaultLanguage = () => {},
  setModalLarge = () => {},
  setModelDomDefault = () => {},
}) {
  const dropdownRef = ref(undefined);
  const idClose = "close_translate";

  const {
    openNotSavedConfirm,
  } = NotSavedConfirmAPI({
    countChangedTranslations,
  });

  const openModal = () => {
    initModelLanguage();
    initModelSearch();
    setModelDomDefault();
    clearModelPlaceholders();
    setModalLarge();
  };

  const closeDropdown = () => {
    hidePlaceholdersTranslate();
    deactivateModeFindElementWithTranslation();
    setDefaultLanguage();
    clearModel();
  };

  const onCloseModal = () => {
    dropdownRef.value?.onClose({ trigger: "click" });
  };

  const closeModal = () => {
    openNotSavedConfirm({
      callback: onCloseModal,
      ids: idClose,
    });
  };

  return {
    closeDropdown,
    closeModal,
    dropdownRef,
    idClose,
    openModal,
  };
}
