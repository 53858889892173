import UiValidatedJsonMixin from "./UiValidatedJsonMixin";


export default {
  mixins: [
    UiValidatedJsonMixin,
  ],
  props: {
    model: {
      required: true
    },
    options: {
      type: Object,
      default: () => ({}),
      required: true,
      info: "Optionen für den Parameter"
    },
    idPrefix: {
      type: String,
      reqiured: true
    },
    change: {
      type: Function,
      reqiured: true
    },
    errors: {},
  },
  beforeCreate() {
    this.$options.components = this.$options.components || {};
    this.$options.components.ParameterFormular = require("../../ParameterFormular/ParameterFormular.vue").default;
  },
};
