import {
  ref,
} from "vue";

import EventBus from "aloha-vue/src/utils/EventBus";
import {
  toggleTranslate,
  updateTranslation,
} from "aloha-vue/src/ATranslation/compositionAPI/ATranslationAPI";

export default function TranslatePlaceholdersAPI() {
  const isPlaceholdersTranslateVisible = ref(false);
  const dataPlaceholders = [
    {
      value: true,
      label: "_TXT_YES_",
      title: "_LBL_MODAL_TRANSLATE_SHOW_PLACEHOLDERS_",
      icon: "t-circle-slash",
    },
    {
      value: false,
      label: "_TXT_NO_",
      title: "_LBL_MODAL_TRANSLATE_SHOW_TRANSLATIONS_",
      icon: "t-circle",
    },
  ];

  const showPlaceholdersTranslate = () => {
    toggleTranslate(false);
    updateTranslation();
    setTimeout(() => {
      isPlaceholdersTranslateVisible.value = true;
      window.statusShowPlaceholdersTranslate = isPlaceholdersTranslateVisible.value;
      EventBus.$emit("showPlaceholdersTranslate", ({ statusShow: window.statusShowPlaceholdersTranslate }));
    });
  };

  const hidePlaceholdersTranslate = () => {
    if (!isPlaceholdersTranslateVisible.value) {
      return;
    }
    toggleTranslate(true);
    updateTranslation();
    setTimeout(() => {
      isPlaceholdersTranslateVisible.value = false;
      window.statusShowPlaceholdersTranslate = isPlaceholdersTranslateVisible.value;
      EventBus.$emit("showPlaceholdersTranslate", ({ statusShow: window.statusShowPlaceholdersTranslate }));
    });
  };

  const togglePlaceholdersTranslate = () => {
    if (isPlaceholdersTranslateVisible.value) {
      hidePlaceholdersTranslate();
    } else {
      showPlaceholdersTranslate();
    }
  };

  return {
    dataPlaceholders,
    hidePlaceholdersTranslate,
    isPlaceholdersTranslateVisible,
    togglePlaceholdersTranslate,
  };
}
