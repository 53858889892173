import {
  computed,
} from "vue";

import {
  isAuthenticated,
  me,
  getUser,
} from "./UserAPI";

import KontextTypes from "../const/KontextTypes";
import {
  isString,
  isArray,
  indexOf,
  isUndefined,
  forEach,
} from "lodash-es";


export default function PermissionAPI() {
  const fullName = computed(() => {
    if (!isAuthenticated.value) {
      return "";
    }
    const parts = [];
    if (me.value.n_vorname) {
      parts.push(me.value.n_vorname);
    }
    if (me.value.n_nachname) {
      parts.push(me.value.n_nachname);
    }
    if (parts.length === 0) {
      parts.push(me.value.n_loginname);
    }
    return parts.join(" ");
  });

  const _checkPermissionsSync = ({ perm, permArray, useOnlyObjectPermission }) => {
    return checkPermissionsSync({ perm, permArray, useOnlyObjectPermission });
  };

  const checkContextTypeSync = ({ contextType }) => {
    if (isUndefined(contextType)) {
      return true;
    }

    if (isUndefined(me.value) || !me.value.kontexte) {
      return false;
    }
    for (let i = 0; i < me.value.kontexte.length; i++) {
      if (me.value.kontexte[i].kontext_typ === KontextTypes[contextType]) {
        return true;
      }
    }
    return false;
  };

  const preparePermissionsList = ({ response }) => {
    const PERMISSIONS_LIST = [];
    const PERMISSIONS_OBJ = {};
    forEach(response, fGroup => {
      forEach(fGroup.berechtigungen, permission => {
        if (!PERMISSIONS_OBJ[permission.key]) {
          PERMISSIONS_LIST.push(permission);
          PERMISSIONS_OBJ[permission.key] = true;
        }
      });
    });
    return PERMISSIONS_LIST;
  };

  return {
    checkContextTypeSync,
    checkPermissionsSync: _checkPermissionsSync,
    fullName,
    hasOnePermissions,
    me,
    preparePermissionsList,
  };
}

export function checkPermissionsSync({ perm, permArray, useOnlyObjectPermission }) {
  if (isUndefined(perm) || (isArray(perm) && !perm.length)) {
    return true;
  }
  let permArrayLocal = [];
  if (permArray || useOnlyObjectPermission) {
    permArrayLocal = permArray || [];
  } else {
    if (me.value && me.value.permissions) {
      permArrayLocal = me.value.permissions;
    }
  }
  return _checkPermissionsWithArray({ perm, permArray: permArrayLocal });
}

function _checkPermissionsWithArray({ perm, permArray }) {
  if (isString(perm)) {
    return _checkSinglePermissions({ perm, permArray });
  } else if (isArray(perm)) {
    return _checkArrayPermissions({ perm, permArray });
  }
  return false;
}

function _checkArrayPermissions({ perm, permArray }) {
  for (let i = 0; i < perm.length; i++) {
    const CURRENT_PERM = perm[i];
    if (permArray.indexOf(CURRENT_PERM) === -1) {
      return false;
    }
  }
  return true;
}

function _checkSinglePermissions({ perm, permArray }) {
  return isArray(permArray) && indexOf(permArray, perm) !== -1;
}

export function checkPermissions(perm) {
  return new Promise(resolve => {
    getUser().then(
      response => {
        if (!response || !isArray(response.permissions)) {
          resolve(false);
        } else {
          resolve(_checkPermissionsWithArray({ perm, permArray: response.permissions }));
        }
      },
      () => {
        resolve(false);
      }
    );
  });
}

export function hasOnePermissions({ perm = [], permArray }) {
  for (let i = 0; i < perm.length; i++) {
    if (_checkSinglePermissions({ perm: perm[i], permArray })) {
      return true;
    }
  }
  return false;
}
