import {
  computed,
  ref,
} from "vue";

import {
  escape,
  escapeRegExp,
  forEach,
  toLower,
} from "lodash-es";

export default function SearchAPI({
  allElementsWithTranslation = ref([]),
}) {
  const isAnyElementFoundLocal = ref(false);
  const modelSearch = ref("");
  const modelSearchLocal = ref("");
  const searchHideElements = ref({});
  let timer = undefined;

  const isAnyElementFound = computed(() => {
    if (modelSearch.value) {
      return isAnyElementFoundLocal.value;
    }

    return allElementsWithTranslation.value.length > 0;
  });

  const modelSearchLowerCase = computed(() => {
    return toLower(modelSearch.value || "");
  });

  const modelSearchEscapeRegExp = computed(() => {
    return escapeRegExp(modelSearchLowerCase.value);
  });

  const modelSearchRE = computed(() => {
    return new RegExp(modelSearchEscapeRegExp.value, "gi");
  });

  const initModelSearch = () => {
    modelSearch.value = "";
    modelSearchLocal.value = "";
  };

  const searchElements = () => {
    if (!modelSearch.value) {
      searchHideElements.value = {};
      isAnyElementFoundLocal.value = false;

      return;
    }

    isAnyElementFoundLocal.value = false;
    const SEARCH_HIDE_ELEMENTS = {};
    forEach(allElementsWithTranslation.value, element => {
      if (element.placeholder.search(modelSearchRE.value) !== -1 ||
        element.text.search(modelSearchRE.value) !== -1) {
        isAnyElementFoundLocal.value = true;
      } else {
        SEARCH_HIDE_ELEMENTS[element.placeholder] = true;
      }
    });

    searchHideElements.value = SEARCH_HIDE_ELEMENTS;
  };

  const changeModelSearch = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      modelSearch.value = escape(modelSearchLocal.value);
      searchElements();
    }, 300);
  };

  return {
    changeModelSearch,
    initModelSearch,
    isAnyElementFound,
    modelSearch,
    modelSearchLocal,
    searchHideElements,
  };
}
