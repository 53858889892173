import {
  computed,
  ref,
  toRef,
} from "vue";

import ANotificationAPI from "aloha-vue/src/compositionAPI/ANotificationAPI";

import EventBus from "aloha-vue/src/utils/EventBus";

export default function DomAPI(props) {
  const data = toRef(props, "data");

  const elementHighlighted = ref(false);
  const elementRef = ref(undefined);

  const {
    addNotification,
  } = ANotificationAPI();

  const iconForHighlight = computed(() => {
    return elementHighlighted.value ?
      "eye-close" :
      "eye";
  });

  const titleForHighlight = computed(() => {
    return elementHighlighted.value ?
      "_BTN_MODAL_TRANSLATE_HIDE_IN_DOM_TITLE_" :
      "_BTN_MODAL_TRANSLATE_SHOW_IN_DOM_TITLE_";
  });

  const scrollToElement = el => {
    el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  };

  const highlight = el => {
    elementRef.value = el;
    elementRef.value.classList.add("el_translate_highlight");
    elementHighlighted.value = true;
  };

  const isVisible = el => {
    return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
  };

  const showHighlightInDom = () => {
    const element = document.querySelector(data.value.selector);
    if (!element) {
      addNotification({
        text: "_MSG_MODAL_TRANSLATE_SAVE_ELEMENT_NOT_FOUND_",
        type: "warning",
      });
      return;
    }

    if (isVisible(element)) {
      scrollToElement(element);
      highlight(element);
    } else {
      let parent = element.parentElement;
      while (parent && !isVisible(parent) && parent.tagName !== "BODY") {
        parent = parent.parentElement;
      }

      if (parent && isVisible(parent) && parent.tagName !== "BODY") {
        scrollToElement(parent);
        highlight(parent);
      } else {
        addNotification({
          text: "_MSG_MODAL_TRANSLATE_SAVE_ELEMENT_AND_PARENTS_HIDDEN_",
          type: "warning",
        });
      }
    }
  };

  const hideHighlightInDom = () => {
    if (elementRef.value) {
      elementRef.value.classList.remove("el_translate_highlight");
    }
    elementHighlighted.value = false;
  };

  const toggleHighlightInDom = () => {
    if (elementHighlighted.value) {
      hideHighlightInDom();
    } else {
      EventBus.$emit("hideHighlightInDom");
      showHighlightInDom();
    }
  };

  const initEventBus = () => {
    EventBus.$on("hideHighlightInDom", hideHighlightInDom);
  };

  const destroyEventBus = () => {
    EventBus.$off("hideHighlightInDom", hideHighlightInDom);
  };

  return {
    destroyEventBus,
    hideHighlightInDom,
    iconForHighlight,
    initEventBus,
    titleForHighlight,
    toggleHighlightInDom,
  };
}
