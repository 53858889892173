import AButton from "aloha-vue/src/AButton/AButton";
import AAlert from "aloha-vue/src/AAlert/AAlert";
import ATranslation from "aloha-vue/src/ATranslation/ATranslation";
import FormElement from "../FormElement/FormElement.vue";
import FormElementReadOnly from "../FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxErrors from "../PuxErrors/PuxErrors.vue";

import Loading from "../../directives/loading";
import translate from "../../directives/translate";
import AutoFocus from "../../directives/AutoFocus";

import {
  cloneDeep,
  filter,
  forEach,
  get,
  isArray,
  isFunction,
  isString,
} from "lodash-es";

const TAB_ELEMENTS = `a[href]:not([disabled]) a[role="button"]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled])`;
const KEY_CODE_TAB = 9;
const KEY_CODE_ESCAPE = 27;

// @vue/component
export default {
  name: "Modal",
  components: {
    AAlert,
    AButton,
    ATranslation,
    FormElement,
    FormElementReadOnly,
    PuxErrors,
  },
  directives: {
    Loading,
    translate,
    AutoFocus,
  },
  props: {
    alertLabels: {
      type: Object,
      default: undefined,
    },
    change: {
      type: Function,
      default: () => {},
    },
    close: {
      type: Function,
      default: undefined,
    },
    confirmOptions: {
      type: Object,
      default: () => ({
        // okClass: "a_btn a_btn_primary",
        // title: "",
        // msg: "",
        // okLabel: "",
        // cancelLabel: "",
        // okCallback: () => {},
        // cancelCallback: () => {},
        // loading: false,
      }),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    dataForm: {
      type: Array,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: [Object, Array],
      default: undefined,
    },
    errorsClone: {
      type: [Object, Array],
      default: () => ({}),
    },
    errorsLabelsOptions: {
      type: Array,
      required: false,
      default: undefined,
    },
    header: {
      type: String,
      required: false,
      default: undefined,
    },
    info: { // Info-I
      type: String,
      required: false,
      default: undefined,
    },
    loading: {
      type: Boolean,
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    save: {
      type: Function,
      default: undefined,
    },
    selectorClose: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
    selectorCloseIds: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
    size: {
      type: String,
      validator: value => ["xl", "lg", "md", "sm"].indexOf(value) !== -1,
      default: "lg",
    },
    withoutEscape: {
      type: Boolean,
    },
  },
  data() {
    return {
      clientHeight: 0,
      statusShow: false,
      modalInModalCount: 0,
      statusShowInfo: true,
    };
  },
  computed: {
    headerLocal() {
      return this.header || this.options.header;
    },

    listLocal() {
      return this.dataForm || this.options.list;
    },

    listFiltered() {
      if (this.options.showReadonlyInEdit) {
        return this.listLocal;
      }
      return filter(this.listLocal, item => !item.readonly);
    },

    formElementComponentMap() {
      const MAP = {};
      forEach(this.listLocal, el => {
        MAP[el.id] = el.readonly ? "FormElementReadOnly" : "FormElement";
      });
      return MAP;
    },

    confirmOkLabel() {
      return this.confirmOptions.okLabel || "_BTN_SAVE_";
    },

    confirmCancelLabel() {
      return this.confirmOptions.cancelLabel || "_BTN_CANCEL_";
    },

    cancelLabel() {
      return this.options.cancelLabel || "_BTN_CANCEL_";
    },

    autocomplete() {
      return this.options.autocomplete || "on";
    },

    classSize() {
      return `modal-${ this.size }`;
    },

    backdropStyle() {
      if (this.modalInModalCount) {
        return `z-index: ${ 1045 + (this.modalInModalCount * 10) };`;
      }
      return "";
    },

    modalStyle() {
      if (this.modalInModalCount) {
        return `z-index: ${ 1050 + (this.modalInModalCount * 10) };`;
      }
      return "";
    },

    htmlIdModalChild() {
      return `modal_child_${ this.modalInModalCount }`;
    },

    htmlIdModal() {
      return `modal_${ this.modalInModalCount }`;
    },

    htmlIdHeader() {
      return `modal_header_${ this.modalInModalCount }`;
    },

    dependencyValues() {
      const DEPS = {};
      forEach(this.listFiltered, item => {
        if (item.dependency) {
          DEPS[item.id] = get(this.model, item.dependency);
        }
      });
      return DEPS;
    },

    selectorsCloseAll() {
      const ALL_SELECTORS = [];
      if (this.selectorCloseIds) {
        if (isString(this.selectorCloseIds)) {
          ALL_SELECTORS.push(`#${ this.selectorCloseIds }`);
        } else if (isArray(this.selectorCloseIds)) {
          forEach(this.selectorCloseIds, selectorCloseId => {
            ALL_SELECTORS.push(`#${ selectorCloseId }`);
          });
        }
      }
      if (this.selectorClose) {
        if (isString(this.selectorClose)) {
          ALL_SELECTORS.push(this.selectorClose);
        } else if (isArray(this.selectorClose)) {
          ALL_SELECTORS.push(...this.selectorClose);
        }
      }

      return ALL_SELECTORS;
    }
  },
  mounted() {
    if ($(".modal-backdrop").length) {
      this.modalInModalCount = $(".modal-backdrop").length;
    }
    $("body").addClass("modal-open a_pr_4");
    // document.body.appendChild(this.$el);
    this.setListenerForPressButtons();
    setTimeout(() => {
      this.statusShow = true;
    });
  },
  unmounted() {
    // if (this.$el && isFunction(this.$el.remove)) {
    //   this.$el.remove();
    // } else {
    //   const MODAL = document.getElementById(this.htmlIdModalChild);
    //   if (MODAL && MODAL.parentNode && isFunction(MODAL.parentNode.removeChild)) {
    //     MODAL.parentNode.removeChild(MODAL);
    //   }
    // }
    $("body").removeClass("modal-open a_pr_4");
    this.onFocusByDestroy();
    this.removeMouseEventListeners();
    this.removeListenerForPressButtons();
  },
  methods: {
    setListenerForPressButtons() {
      document.addEventListener("keydown", this.pressButton);
    },

    pressButton($event) {
      const EVENT = $event || window.$event;
      if (EVENT.key === "Escape" || EVENT.keyCode === KEY_CODE_ESCAPE) { // Escape
        this.pressEscape();
      } else if (EVENT.key === "Tab" || EVENT.keyCode === KEY_CODE_TAB) { // Tab
        this.trapFocus(EVENT);
      }
    },

    pressEscape() {
      if (this.withoutEscape) {
        return;
      }
      if (this.confirmOptions && isFunction(this.confirmOptions.cancelCallback)) {
        this.confirmOptions.cancelCallback();
      } else {
        this.onClose();
      }
    },

    trapFocus(EVENT) {
      const FOCUSABLE_ELEMENTS = this.$refs.modal_parent.querySelectorAll(TAB_ELEMENTS);
      if (FOCUSABLE_ELEMENTS.length === 0) {
        EVENT.preventDefault();
        return;
      }
      const FIRST_FOCUSABLE_ELEMENT = FOCUSABLE_ELEMENTS[0];
      const LAST_FOCUSABLE_ELEMENT = FOCUSABLE_ELEMENTS[FOCUSABLE_ELEMENTS.length - 1];
      if (EVENT.shiftKey) { // Shift + Tab
        if (document.activeElement === FIRST_FOCUSABLE_ELEMENT) {
          this.setFocusToModal(EVENT);
          EVENT.preventDefault();
        } else if (document.activeElement === this.$refs.modal) { // Focus in .modal
          LAST_FOCUSABLE_ELEMENT.focus();
          EVENT.preventDefault();
        }
      } else { // Tab
        if (document.activeElement === LAST_FOCUSABLE_ELEMENT) {
          this.setFocusToModal(EVENT);
          EVENT.preventDefault();
        }
      }
    },

    setFocusToModal(EVENT) {
      if (this.$refs.modal) {
        this.$refs.modal.focus();
      }
      EVENT.preventDefault();
    },

    removeListenerForPressButtons() {
      document.removeEventListener("keydown", this.pressButton);
    },

    onClose() {
      if (isFunction(this.close)) {
        this.close();
      }
    },

    onInput({ id }) {
      if (this.errorsClone[id]) {
        // eslint-disable-next-line vue/no-mutating-props
        delete this.errorsClone[id];
      }
    },

    mousedown() {
      this.addMouseEventListeners();
      this.clientHeight = document.documentElement.clientHeight;
    },

    mousemove($event) {
      const CLIENT_Y = $event.clientY;
      const MODAL_BODY_HEIGHT = this.getModalBodyHeight(CLIENT_Y);
      this.$refs.modal_body.style.maxHeight = `${ MODAL_BODY_HEIGHT }px`;
      this.$refs.modal_body.style.height = `${ MODAL_BODY_HEIGHT }px`;
    },

    mouseup() {
      this.removeMouseEventListeners();
    },

    mouseoutDocument() {
      this.removeMouseEventListeners();
    },

    addMouseEventListeners() {
      document.addEventListener("mousemove", this.mousemove);
      document.addEventListener("mouseup", this.mouseup);
      document.addEventListener("mouseleave", this.mouseoutDocument);
    },

    removeMouseEventListeners() {
      document.removeEventListener("mouseup", this.mouseup);
      document.removeEventListener("mousemove", this.mousemove);
      document.removeEventListener("mouseout", this.mouseoutDocument);
    },

    getModalBodyHeight(clientY) {
      const CLIENT_Y = clientY + 30 > this.clientHeight ? this.clientHeight - 15 : clientY;
      const MODAL_HEADER_HEIGHT = this.$refs.modal_header.clientHeight;
      const MODAL_FOOTER_HEIGHT = this.$refs.modal_footer.clientHeight;
      const MODAL_BODY_HEIGHT = CLIENT_Y - MODAL_HEADER_HEIGHT - MODAL_FOOTER_HEIGHT - 15;
      return MODAL_BODY_HEIGHT > 0 ? MODAL_BODY_HEIGHT : 0;
    },

    onFocusByDestroy() {
      if (!this.selectorsCloseAll.length) {
        return;
      }
      const SELECTOR_CLOSE_ALL = cloneDeep(this.selectorsCloseAll);

      setTimeout(() => {
        forEach(SELECTOR_CLOSE_ALL, selector => {
          const STATUS_SUCCESS = this.onFocusByDestroyForSelector({ selector });
          if (STATUS_SUCCESS) {
            return false;
          }
        });
      }, 300);
    },

    onFocusByDestroyForSelector({ selector }) {
      const ELEMENT = $(selector);
      // const ELEMENT = document.querySelector(selector); TODO: ILIA
      if (ELEMENT.length > 0) {
        ELEMENT.focus();
        return true;
      }
      return false;
    },

    saveLocal() {
      if (isFunction(this.save)) {
        this.save();
      }
    },

    hideInfo() {
      this.statusShowInfo = false;
    },
  },
};
