import {
  computed,
} from "vue";

import AConfirmAPI from "aloha-vue/src/compositionAPI/AConfirmAPI";

export default function NotSavedConfirmAPI({
  countChangedTranslations = computed(() => 0),
}) {
  const {
    closeConfirm,
    openConfirm,
  } = AConfirmAPI();

  const submit = ({ callback }) => {
    closeConfirm();
    callback();
  };

  const openNotSavedConfirm = ({ callback, ids }) => {
    if (!countChangedTranslations.value) {
      callback();
      return;
    }

    openConfirm({
      bodyHtml: "_TXT_MODAL_TRANSLATE_CONFIRM_BODY_{{count}}_",
      extra: {
        count: countChangedTranslations.value,
      },
      selectorCloseIds: ids,
      save: () => submit({ callback }),
      headerText: "_TXT_MODAL_TRANSLATE_CONFIRM_HEADER_",
      saveButtonText: "_BTN_FORTFAHREN_",
      closeButtonText: "_BTN_CANCEL_",
      zIndex: 1900,
    });
  };

  return {
    openNotSavedConfirm,
  };
}
