import AButton from "aloha-vue/src/AButton/AButton";
import ASpinner from "aloha-vue/src/ASpinner/ASpinner";
import PuxIcon from "../../PuxIcon/PuxIcon.vue";
import PuxLabel from "../../FormElement/PuxLabel/PuxLabel.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";
import UiSelectElement from "./UiSelectElement/UiSelectElement.vue";

import Loading from "../../../directives/loading";
import Teleport from "../../../directives/Teleport";
import translate from "../../../directives/translate";

import EventOutsideMixin from "../../../mixins/EventOutsideMixin";
import HttpMixin from "../../../mixins/HttpMixin";
import UIComponentMixin from "../UIComponentMixin";

import { createPopper } from "@popperjs/core/lib/popper-lite";
import flip from "@popperjs/core/lib/modifiers/flip";
import preventOverflow from "@popperjs/core/lib/modifiers/preventOverflow";

import {
  assign,
  cloneDeep,
  concat,
  filter,
  find,
  forEach,
  get,
  has,
  isArray,
  isFunction,
  isMatch,
  isNil,
  isNumber,
  isString,
  isUndefined,
  keys,
  map,
  noop,
  pick,
  size,
  some,
  sortBy,
  toLower,
  toString,
  uniq,
  uniqBy,
  values,
} from "lodash-es";

const ELEMENTS_FOR_ARROWS = ".ui_select__element_clickable:not([disabled])";
const KEYS_CODE = {
  tab: 9,
  space: 32,
  enter: 13,
  arrowUp: 38,
  arrowDown: 40,
  escape: 27,
};

// @vue/component
export default {
  name: "UiSelect",
  components: {
    ASpinner,
    UiSelectElement,
    AButton,
    PuxIcon,
    PuxLabel,
    PuxTranslate,
  },
  directives: {
    Loading,
    Teleport,
    translate,
  },
  mixins: [
    EventOutsideMixin,
    HttpMixin,
    UIComponentMixin,
  ],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    model: {
      type: [String, Array, Number],
      default: undefined,
    },
    loadListCallback: {
      type: Function,
      default: undefined,
    },
    dependencyValue: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      list: undefined,
      statusListLoading: undefined,
      modelSearch: "",
      modelSearchGlobal: "",
      lastSearch: "",
      lastSearchGlobal: "",
      listSearchGlobal: [],
      listSearchGlobalInit: [],
      listSelectedInvalid: [],
      promisesAll: [],
      status: {
        loadingSearchGlobal: undefined,
      },
      statusEventPressArrows: undefined,
      focusLocal: undefined,
      popper: undefined,
      isOpen: false,
    };
  },
  computed: {
    getPlacement() {
      return this.options.placement || "bottom-end"; // bottom-start, top-start, top-end, left, right
    },

    ariaRequired() {
      return this.isRequired ? "true" : "false";
    },

    ariaExpanded() {
      return `${ this.isOpen }`;
    },

    getConteinerId() {
      return `${ this.options.id }_conteiner`;
    },

    modelObj() {
      const list = this.options.searchGlobal ? concat(this.getCurrentList, this.listSearchGlobal) : this.getCurrentList;
      return find(list, [this.keyIdLocal, this.model]) || find(list, [this.keyIdLocal, String(this.model)]) || {};
    },

    getCurrentList() {
      return this.getCurrentListWithSort;
    },

    getCurrentListWithSort() {
      if (this.options.sortList) {
        return sortBy(this.getListModelList, this.options.sortList);
      }
      return this.getListModelList;
    },

    getList() {
      return this.options.data || this.list || this.data;
    },

    getListModelList() {
      if (this.options.addNew) {
        const LIST = cloneDeep(this.getList);
        if (this.isMultiselect) {
          if (this.model.length) {
            return uniq(concat(this.model, LIST));
          }
        } else {
          if (!isNil(this.model)) {
            LIST.unshift(this.model);
            return uniq(LIST);
          }
        }
      }

      if (this.options.dependency && this.dependencyValue && this.dependencyLocal) {
        return filter(this.getList, el => {
          return toLower(el[this.dependencyKey]) === toLower(this.dependencyValue);
        });
      }
      return this.getList;
    },

    getListComputed() {
      let currentList;
      if (this.options.searchGlobal) {
        currentList = this.getListWithSearchGlobal;
      } else if (!this.options.search || this.options.searchOutside || this.modelSearch.length === 0) {
        currentList = this.getCurrentList;
      } else {
        currentList = this.getListWithSearch;
      }
      if (size(this.listSelectedInvalid) > 0) {
        return concat(this.listSelectedInvalid, currentList);
      }
      return currentList;
    },

    getListWithSearchGlobal() {
      return this.listSearchGlobal;
    },

    getListWithSearch() {
      const list = [];
      const search = this.modelSearch.toLowerCase();
      if (this.options.keyArray) {
        forEach(this.getCurrentList, item => {
          if (toString(item).toLowerCase().indexOf(search) !== -1) {
            list.push(item);
          }
        });
      } else {
        forEach(this.getCurrentList, item => {
          for (let i = 0; i < this.searchList.length; i++) {
            if (toString(item[this.searchList[i]]).toLowerCase().indexOf(search) !== -1) {
              list.push(item);
              break;
            }
          }
        });
      }
      return list;
    },

    getListWithGroup() {
      const data = {};
      if (this.options.keyGroupList && this.options.keyGroupList.length) {
        forEach(this.options.keyGroupList, item => {
          data[item] = [];
        });
        forEach(this.listWithoutInvalidEntries, item => {
          if (!isNil(item[this.keyGroupLocal])) {
            const labelGroup = item[this.keyGroupLocal].toLocaleString();
            if (labelGroup) {
              data[labelGroup].push(item);
            }
          }
        });
        return data;
      }
      forEach(this.listWithoutInvalidEntries, item => {
        if (!isNil(item[this.keyGroupLocal])) {
          const labelGroup = item[this.keyGroupLocal].toLocaleString();
          if (!data[labelGroup]) {
            data[labelGroup] = [];
          }
          data[labelGroup].push(item);
        }
      });
      return data;
    },

    listWithNotEmptyGroup() {
      const LIST_WITH_GROUP = cloneDeep(this.getListWithGroup);
      forEach(LIST_WITH_GROUP, (list, key) => {
        if (!list.length) {
          delete LIST_WITH_GROUP[key];
        }
      });
      return LIST_WITH_GROUP;
    },

    listWithNotEmptyGroupSortedKeys() {
      return keys(this.listWithNotEmptyGroup).sort((a, b) => a.localeCompare(b));
    },

    listWithoutInvalidEntries() {
      return filter(this.getListComputed, el => !el.__invalidEntry__);
    },

    getListWithoutGroup() {
      if (!this.keyGroupLocal) {
        return this.listWithoutInvalidEntries;
      }
      return filter(this.listWithoutInvalidEntries, item => isNil(item[this.keyGroupLocal]));
    },

    getModelMulti() {
      const list = this.uniqueList(concat(this.getCurrentList, this.options.searchGlobal ? this.listSearchGlobal : [], this.listSelectedInvalid));
      const LIST_NEW = filter(list, item => some(this.model, mod => {
        const id = this.options.keyArray ? item : item[this.keyIdLocal];
        return mod === id;
      })) || [];
      if (this.hasEmptyValue && this.model.indexOf(this.options.emptyValue) !== -1) {
        LIST_NEW.unshift({
          [this.keyIdLocal]: this.options.emptyValue,
          [this.keyLabelLocal]: this.options.emptyLabel,
        });
      }
      if (this.options.extraValue && this.model.indexOf(this.options.extraValue) !== -1) {
        LIST_NEW.unshift({
          [this.keyIdLocal]: this.options.extraValue,
          [this.keyLabelLocal]: this.options.extraLabel,
        });
      }
      return LIST_NEW;
    },

    isSearchInList() {
      const SEARCH = (this.modelSearch || this.modelSearchGlobal).toLowerCase();
      const FUNC = this.options.keyArray ?
        item => {
          return item.toLowerCase() === SEARCH;
        }
        :
        item => {
          for (let i = 0; i < this.searchList.length; i++) {
            if (toString(item[this.searchList[i]]).toLowerCase() === SEARCH) {
              return true;
            }
          }
          return false;
        };
      return find(this.getListComputed, FUNC);
    },

    isAlreadySearched() {
      return (
        (this.modelSearch && this.modelSearch === this.lastSearch)
        ||
        (this.modelSearchGlobal && this.modelSearchGlobal === this.lastSearchGlobal)
      );
    },

    getItemClass() {
      return item => {
        const CLASS_MAP = {
          ui_select__menu__link_selected: this.itemChecked(item),
        };
        if (this.options.elementItemClass) {
          CLASS_MAP[this.options.elementItemClass] = true;
        }
        return CLASS_MAP;
      };
    },

    getItemEmptyClass() {
      return {
        ui_select__menu__link_selected: this.typeLocal !== "multiselect" ?
          this.model === this.options.emptyValue :
          this.model.indexOf(this.options.emptyValue) !== -1,
      };
    },

    getItemExtraClass() {
      return {
        ui_select__menu__link_selected: this.typeLocal !== "multiselect" ?
          this.model === this.options.extraValue :
          this.model.indexOf(this.options.extraValue) !== -1,
      };
    },

    isItemChecked() {
      return item => toString(this.itemChecked(item));
    },

    hasEmptyValue() {
      return has(this.options, "emptyValue") && this.options.emptyValue !== undefined;
    },

    isItemEmptyChecked() {
      return (this.typeLocal !== "multiselect" && this.model === this.options.emptyValue) ||
      (this.isMultiselect && this.model.indexOf(this.options.emptyValue) !== -1) ?
        "true" :
        "false";
    },

    isItemExtraChecked() {
      return (this.typeLocal !== "multiselect" && this.model === this.options.extraValue) ||
      (this.isMultiselect && this.model.indexOf(this.options.extraValue) !== -1) ?
        "true" :
        "false";
    },

    isSearchGlobalDisabled() {
      return this.status.loadingSearchGlobal || !this.modelSearchGlobal.length;
    },

    getStylesButton() {
      if (this.options.paddingRight) {
        return ({
          "padding-right": this.options.paddingRight,
        });
      }
      return ({
        "padding-right": this.options.fixedPlaceholderSlot ? "9px" : this.isButtonDeleteVisible ? "45px" : "35px",
      });
    },

    isDisabled() {
      if (this.options.disabled || this.disabled || this.loading || this.options.loading) {
        return true;
      }
      return undefined;
    },

    emptyLabel() {
      return this.options.emptyLabel || "_LBL_UI_SELECT_ELEMENT_EMPTY_";
    },

    extraLabel() {
      return this.options.extraLabel || "_LBL_UI_SELECT_ELEMENT_EXTRA_NO_LBL_";
    },

    placeholder() {
      if (this.options.view === "v") {
        return;
      }
      return this.options.placeholder || "_LBL_UI_SELECT_ELEMENT_PLACEHOLDER_";
    },

    keyGroupLocal() {
      if (this.options.withoutGroup) {
        return;
      }
      return this.options.keyGroup || "group";
    },

    searchList() {
      return this.options.searchList || [];
    },

    closeClick() {
      if (!isUndefined(this.options.closeClick)) {
        return this.options.closeClick;
      }
      return this.typeLocal !== "multiselect";
    },

    nullInListComputed() {
      return find(this.getListComputed, [this.keyIdLocal, null]);
    },

    notModelSingleSelect() {
      return isNil(this.model);
    },

    textSearchLocal() {
      return this.options.textsSearch || "Suche";
    },

    isButtonDeleteVisible() {
      return isUndefined(this.options.buttonDelete) ? false : this.options.buttonDelete;
    },

    countMultiLocal() {
      return this.options.countMulti || 3;
    },

    isDeselectAllowed() {
      return isUndefined(this.options.deselect) ? false : this.options.deselect;
    },

    isButtonDeselectAllVisible() {
      return isUndefined(this.options.deselectAll) ? false : this.options.deselectAll;
    },

    maxHeightLocal() {
      return this.options.maxHeight || "300px";
    },

    keyLabelLocal() {
      return this.options.keyLabel || "label";
    },

    keyIdLocal() {
      return this.options.keyId || "id";
    },

    menuRightLocal() {
      return isUndefined(this.options.menuRight) ? false : this.options.menuRight;
    },

    menuStylesLocal() {
      return this.options.menuStyles || "";
    },

    isRequired() {
      return isUndefined(this.options.required) ? false : this.options.required;
    },

    isCaretVisible() {
      return isUndefined(this.options.showCaret) ? true : this.options.showCaret;
    },

    isButtonSelectAllVisible() {
      return isUndefined(this.options.selectAll) ? false : this.options.selectAll;
    },

    isMenuWidthAll() {
      return isUndefined(this.options.menuWidthAll) ? true : this.options.menuWidthAll;
    },

    buttonClassDefault() {
      if (this.options.ignoreButtonClassDefault) {
        return undefined;
      }
      return "ui-select-toggle form_control form-control";
    },

    buttonClassLocal() {
      return `${ this.options.buttonClass || "" } ${ this.options.fixedPlaceholderSlot ? "" : "form-control" } ${ this.isDisabledLocal ? "disabled" : "" }`;
    },

    combineCopies() {
      return this.options.combineCopies || false;
    },

    onOpen() {
      return this.options.open || noop;
    },

    elementItemClass() {
      return this.options.elementItemClass;
    },

    typeLocal() {
      if (!this.options.type || this.options.type === "single_choice") {
        return "select";
      } else if (this.options.type === "multi_select") {
        return "multiselect";
      }
      return this.options.type;
    },

    textSelectAll() {
      return this.options.textsSelectAll || "_LBL_UI_SELECT_ALL_CHOOSE_";
    },

    textDeselectAll() {
      return this.options.textsDeselectAll || "_LBL_UI_SELECT_ALL_DEMARCATE_";
    },

    idForSearchGlobal() {
      return `${ this.getId }_search_global`;
    },

    idForSearchGlobalLabel() {
      return `${ this.getId }_search_global_label`;
    },

    searchLabelOptions() {
      return { label: this.options.searchLabel };
    },

    idForButtonSearchGlobal() {
      return `${ this.getId }_search_global_btn`;
    },

    translateOptionsForSearch() {
      return {
        placeholder: this.textSearchLocal,
        "aria-label": "_TXT_UI_SELECT_SEARCH_ARIA_LABEL_",
      };
    },

    isMultiselect() {
      return this.typeLocal === "multiselect";
    },

    tabindex() {
      return this.isDisabledLocal ? undefined : 0;
    },

    ariaLabelledby() {
      return `${ this.getId }_label`;
    },

    idForList() {
      return `${ this.getId }_list`;
    },

    notLoadListLocal() {
      return get(this.extraStatic, "notLoadList") || this.options.notLoadList;
    },

    isInitLabel() {
      return isFunction(get(this.extraStatic, "initLabel"));
    },

    ariaDisabled() {
      return this.isDisabledLocal ? "true" : "false";
    },

    isDisabledLocal() {
      return this.disabled || this.options.disabled
        || (this.dependencyEmptyDisabled
            && this.dependencyEmpty);
    },

    hasInvalidEntries() {
      return size(this.listSelectedInvalid) > 0;
    },

    dependencyEmpty() {
      return this.dependencyKey && isNil(this.dependencyValue);
    },

    dependencyKey() {
      return this.options.dependencyKey || this.options.dependency;
    },

    dependencyEmptyDisabled() {
      return this.options.dependencyEmptyDisabled;
    },

    dependencyLocal() {
      return get(this.options, "dependencyLocal", true);
    },
  },
  watch: {
    options: function(newVal, oldVal) {
      const OLD = pick(oldVal, ["apiSaveId", "url", "urlParams"]);
      const NEW = pick(newVal, ["apiSaveId", "url", "urlParams"]);
      if (!isNil(oldVal) && !isMatch(OLD, NEW)) {
        if (this.isMultiselect) {
          this.onDeselectAll();
        } else {
          this.changeEmptyInput();
        }
        this.loadData();
      }
    },

    dependencyValue: function() {
      if (this.dependencyLocal) {
        if (this.isMultiselect) {
          this.onDeselectAll();
        } else {
          this.changeEmptyInput();
        }
      } else {
        this.loadData();
      }
    },

    getListWithSearch: {
      deep: true,
      handler: function() {
        this.lastSearch = this.modelSearch;
      }
    },
  },
  created() {
    this.validateProps();
    this.loadData();
  },
  beforeUnmount() {
    this.destroyEventPressArrows();
  },
  methods: {
    validateProps() {
      if (this.isMultiselect && !isArray(this.model)) {
        console.error("UiSelect: Das Model muss ein Array sein. this.options: ", this.options);
      }
    },

    isValidModelValue(value) {
      return (!this.options.emptyValue || value !== this.options.emptyValue)
             && (!this.options.extraValue || value !== this.options.extraValue)
             && (isString(value) || isNumber(value));
    },

    loadCurrentData({ searchGlobal = false }) {
      let url = get(this.options, "urlRetrieve", this.options.url);
      let urlParams = get(this.options, "urlRetrieveParams", this.options.urlParams, {});
      if (searchGlobal) {
        url = this.options.url;
        urlParams = this.options.urlParams;
        this.listSearchGlobal = this.options.data || []; // initiale Auswahlliste bei globaler Suche
      }
      if (!url) {
        return;
      }
      let searchModels = [];
      if (isArray(this.model) && size(this.model) > 0) {
        searchModels = filter(this.model, pk => this.isValidModelValue(pk));
      } else if (this.isValidModelValue(this.model)) {
        searchModels = [this.model];
      }
      searchModels = filter(searchModels, pk => !find(this.getListComputed, el => toString(el[this.keyIdLocal]) === toString(pk)));
      if (size(searchModels) === 0) {
        return;
      }
      const URL_PARAMS = assign(
        urlParams,
        { [this.keyIdLocal]: searchModels, limit: size(searchModels) }
      );
      return this.getHttp({
        url: url,
        urlParams: URL_PARAMS,
      }).then(
        response => {
          const LIST = get(response, "results", response);
          if (searchGlobal) {
            if (size(LIST) > 0) {
              // initiale Daten für Auswahl mit globaler Suche
              this.listSearchGlobal = concat(this.listSearchGlobal, LIST);
            }
            if (size(LIST) !== size(searchModels)) {
              // nicht alle initialen Daten für Auswahl mit globaler Suche gefunden
              // > nach invaliden Einträgen suchen
              this.loadCurrentData({ searchGlobal: false });
            }
          } else {
            // Einträge waren in der eigentlichen Liste nicht vorhanden
            // > invalide Einträge
            this.listSelectedInvalid = filter(LIST, el => !some(this.getListComputed, searchEl => el[this.keyIdLocal] === searchEl[this.keyIdLocal]));
            forEach(this.listSelectedInvalid, el => el.__invalidEntry__ = true);
            if (this.options.sortList) {
              this.listSelectedInvalid = sortBy(this.listSelectedInvalid, this.options.sortList);
            }
          }
          if (isFunction(get(this.extraStatic, "initLabel")) && size(LIST) > 0) {
            forEach(LIST, el => this.initLabelForTable({ response: el }));
          }
        }
      );
    },

    initLabelForTable({ response }) {
      const CHILD_LABEL = this.options.keyLabelCallback
        ? this.options.keyLabelCallback({ item: response })
        : response[this.keyLabelLocal];
      const ID = response[this.keyIdLocal];
      const LABEL = {
        child: CHILD_LABEL,
        parent: this.options.label,
      };
      this.extraStatic.initLabel({ label: LABEL, currentModel: ID, options: this.options });
    },

    loadData() {
      if (!this.notLoadListLocal &&
        this.options.url &&
        !this.options.searchGlobal) {
        this.statusListLoading = true;
        this.getListHttp({
          url: this.options.url,
          apiSaveId: this.options.apiSaveId,
          urlParams: this.options.urlParams,
        }).then(
          response => {
            this.list = this.processResponse(response);
          }
        ).then(
          () => {
            if (isFunction(this.loadListCallback)) {
              this.loadListCallback({
                list: this.list,
                id: this.options.id,
                options: this.options,
              });
            }
            this.statusListLoading = false;
          }
        ).then(
          () => this.loadCurrentData({ searchGlobal: false })
        );
      } else if (this.options.searchGlobal) {
        this.loadCurrentData({ searchGlobal: true });
      }
    },

    processResponse(list) {
      if (this.combineCopies) {
        const LIST_COPY_OBJ = {};
        forEach(list, item => {
          if (LIST_COPY_OBJ[item[this.keyLabelLocal]]) {
            LIST_COPY_OBJ[item[this.keyLabelLocal]][this.keyIdLocal] = `${ LIST_COPY_OBJ[item[this.keyLabelLocal]][this.keyIdLocal] }_${ item[this.keyIdLocal] }`;
          } else {
            LIST_COPY_OBJ[item[this.keyLabelLocal]] = item;
          }
        });
        return values(LIST_COPY_OBJ);
      }
      return list;
    },

    uniqueList(list) {
      if (this.options.keyArray) {
        return uniq(list);
      }
      return uniqBy(list, this.keyIdLocal);
    },

    onBlurSearchGlobal({ $event }) {
      this.onBlur($event);
    },

    onBlur($event) {
      const STAY_IN_PARENT = (this.$refs.ui_select && this.$refs.ui_select.contains($event.relatedTarget)) ||
        (this.$refs.ui_select_menu && this.$refs.ui_select_menu.contains($event.relatedTarget));
      if (STAY_IN_PARENT) {
        return;
      }
      if ($event.currentTarget.id === this.idForButtonSearchGlobal) {
        return;
      }
      this.closePopover();
      this.blur({
        id: this.options.id,
        model: this.model,
        $event: $event,
        param: this.options.param,
      });
      this.focusLocal = false;
    },

    onFocus($event) {
      if (this.focusLocal) {
        return;
      }
      this.focusLocal = true;
      this.focus({
        id: this.options.id,
        model: this.model,
        $event: $event,
        param: this.options.param,
      });
    },

    deleteSingleModel() {
      this.changeInput(this.modelObj[this.keyLabelLocal]);
    },

    changeEmptyInput() {
      if (this.isDisabled) {
        return;
      }
      let item;
      if (this.options.keyArray) {
        item = this.options.emptyValue;
      } else {
        item = {
          [this.keyIdLocal]: this.options.emptyValue,
          [this.keyLabelLocal]: this.options.emptyLabel,
        };
      }
      this.changeInput(item, { setFocus: false });
    },

    changeExtraInput() {
      if (this.isDisabled) {
        return;
      }
      let item;
      if (this.options.keyArray) {
        item = this.options.extraValue;
      } else {
        item = {
          [this.keyIdLocal]: this.options.extraValue,
          [this.keyLabelLocal]: this.options.extraLabel,
        };
      }
      this.changeInput(item);
    },

    changeInput(item, { setFocus = true } = {}) {
      if (this.isDisabled) {
        return;
      }
      let model;
      const currentValue = this.options.keyArray ? item : item[this.keyIdLocal];
      if (this.isMultiselect) {
        model = cloneDeep(this.model) || [];
        const index = model.indexOf(currentValue);
        if (index !== -1) {
          model.splice(index, 1);
        } else {
          model.push(currentValue);
        }
        this.updatePopover();
      } else {
        if (this.model === currentValue) {
          if (!this.isDeselectAllowed) {
            return;
          }
          model = this.nullValue;
        } else {
          model = this.checkUndefinedValue({ value: currentValue });
        }
      }
      this.input({
        currentModel: currentValue,
        item: item,
        id: this.options.id,
        model: model,
        options: this.options,
        param: this.options.param,
      });

      this.change({
        currentModel: currentValue,
        item: item,
        id: this.options.id,
        model: model,
        options: this.options,
        param: this.options.param,
      });
      this.onCloseClick({ setFocus });
    },

    changeSearch() {
      if (this.options.searchOutside) {
        this.options.searchOutside(this.modelSearch);
      }
    },

    onDeselectAll() {
      if (this.isDisabled) {
        return;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.model.splice(0);

      this.change({
        id: this.options.id,
        item: this.getListComputed,
        model: this.model,
        param: this.options.param,
      });

      if (this.input) {
        this.input({
          item: this.getListComputed,
          id: this.options.id,
          model: this.model,
          param: this.options.param,
        });
      }

      this.onCloseClick();
    },

    onSelectAll() {
      if (this.isDisabled) {
        return;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.model.splice(0);
      forEach(this.getListComputed, item => {
        const currentValue = this.options.keyArray ? item : item[this.keyIdLocal];
        // eslint-disable-next-line vue/no-mutating-props
        this.model.push(currentValue);
      });

      this.change({
        currentModel: this.model,
        item: this.getListComputed,
        id: this.options.id,
        model: this.model,
        param: this.options.param,
      });

      if (this.input) {
        this.input({
          currentModel: this.model,
          item: this.getListComputed,
          id: this.options.id,
          model: this.model,
          param: this.options.param,
        });
      }

      this.onCloseClick();
    },

    onSearchGlobal() {
      this.lastSearchGlobal = this.modelSearchGlobal;
      if (this.options.searchOutside) {
        this.options.searchOutside(this.modelSearchGlobal);
        return;
      }

      this.status.loadingSearchGlobal = true;
      this.getChoicesHttp({
        url: this.options.url,
        params: assign({}, this.options.urlParams, { [this.options.searchParameter]: this.modelSearchGlobal })
      }).then(
        response => {
          if (this.options.keyArray) {
            response = map(response, o => o[this.keyIdLocal]);
          }
          this.changeListSearchGlobalWithModel({ response });
        },
      ).then(
        () => {
          this.status.loadingSearchGlobal = false;
          this.setFocusToButtonSearchGlobal();
        }
      );
    },

    setFocusToButtonSearchGlobal() {
      setTimeout(() => {
        if (this.$refs.searchGlobal && this.$refs.searchGlobal.$el) {
          this.$refs.searchGlobal.$el.focus();
        }
      });
    },

    changeListSearchGlobalWithModel({ response = [] } = {}) {
      const LIST_SEARCH_GLOBAL = cloneDeep(this.listSearchGlobal);
      const LIST_SEARCH_GLOBAL_CONCAT = this.uniqueList(concat(LIST_SEARCH_GLOBAL, response));
      const LIST_SEARCH_GLOBAL_WITH_MODEL = [];
      if (this.isMultiselect) {
        if (this.model && this.model.length) {
          forEach(this.model, mod => {
            const OBJ_FROM_LIST_SEARCH_GLOBAL = find(LIST_SEARCH_GLOBAL_CONCAT, (this.options.keyArray ? o => o === mod : [this.keyIdLocal, mod]));
            if (OBJ_FROM_LIST_SEARCH_GLOBAL) {
              LIST_SEARCH_GLOBAL_WITH_MODEL.push(OBJ_FROM_LIST_SEARCH_GLOBAL);
            }
          });
        }
      } else {
        if (!isNil(this.model)) {
          const OBJ_FROM_LIST_SEARCH_GLOBAL = find(LIST_SEARCH_GLOBAL_CONCAT, [this.keyIdLocal, this.model]);
          if (OBJ_FROM_LIST_SEARCH_GLOBAL) {
            LIST_SEARCH_GLOBAL_WITH_MODEL.push(OBJ_FROM_LIST_SEARCH_GLOBAL);
          }
        }
      }

      this.listSearchGlobal = this.uniqueList(concat(LIST_SEARCH_GLOBAL_WITH_MODEL, response));
    },

    addValueToList($event) {
      const MODEL_SEARCH = this.modelSearch || this.modelSearchGlobal;
      if (this.list) {
        this.list.unshift(MODEL_SEARCH);
      } else if (this.listSearchGlobal) {
        this.listSearchGlobal.unshift(MODEL_SEARCH);
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.unshift(MODEL_SEARCH);
      }
      this.changeInput(MODEL_SEARCH, $event);
      this.modelSearch = "";
      this.modelSearchGlobal = "";
    },

    onCloseClick({ setFocus = true } = {}) {
      if (!this.closeClick || !setFocus) {
        return;
      }
      this.closePopover();
      this.setFocusToButton();
    },

    handleKeydown($event) {
      const KEY_CODE = $event.keyCode;
      if (KEY_CODE === KEYS_CODE.enter ||
        KEY_CODE === KEYS_CODE.space) {
        this.togglePopover();
        $event.preventDefault();
      } else if (!this.isOpen &&
        (KEY_CODE === KEYS_CODE.arrowUp ||
          KEY_CODE === KEYS_CODE.arrowDown)) {
        this.openPopover();
        $event.preventDefault();
      }
    },

    setScroll() {
      setTimeout(() => {
        if (size(this.$refs.selectedItem) && this.options.scrollToSelected !== false) {
          const [selectedItem] = this.$refs.selectedItem;
          selectedItem.scrollIntoView();
        } else {
          this.$refs.ui_select_menu_scrollable.scrollTop = 0;
        }
      });
    },

    togglePopover() {
      if (this.isOpen) {
        this.closePopover();
        this.setFocusToButton();
      } else {
        this.openPopover();
        this.setScroll();
      }
    },

    openPopover() {
      if (this.isDisabledLocal) {
        return;
      }
      this.isOpen = true;
      this.setEventClickOutside();
      this.openPopoverWithPopperjs();
    },

    openPopoverWithPopperjs() {
      if (!this.popper) {
        this.popper = createPopper(
          this.$refs.button,
          this.$refs.ui_select_menu,
          {
            removeOnDestroy: true,
            modifiers: [
              flip,
              preventOverflow,
            ],
          },
        );
        this.onShow();
      }
    },

    onShow() {
      // eslint-disable-next-line vue/no-use-computed-property-like-method
      this.onOpen();
      this.setMenuWidth();
      setTimeout(() => {
        this.setFocusForFirstElementInList();
        this.initEventPressArrows();
      });
    },

    setMenuWidth() {
      if (this.isMenuWidthAll) {
        const BUTTON_WIDTH = this.$refs.button.clientWidth;
        const BUTTON_WIDTH_STRING = `${ BUTTON_WIDTH }px`;
        $(this.$refs.ui_select_menu).css("min-width", BUTTON_WIDTH_STRING).css("max-width", BUTTON_WIDTH_STRING);
      }
    },

    initEventPressArrows() {
      if (this.statusEventPressArrows) { // Event ist schon installiert
        return;
      }
      this.statusEventPressArrows = true;
      document.addEventListener("keydown", this.pressButton);
    },

    destroyEventPressArrows() {
      if (!this.statusEventPressArrows) { // Event ist schon zestört
        return;
      }
      this.statusEventPressArrows = false;
      document.removeEventListener("keydown", this.pressButton);
    },

    pressButton($event) {
      const KEY_CODE = $event.keyCode;
      const IS_ARROW_DOWN = KEY_CODE === KEYS_CODE.arrowDown;
      if (IS_ARROW_DOWN ||
        KEY_CODE === KEYS_CODE.arrowUp) {
        this.pressArrows({ isArrowDown: IS_ARROW_DOWN });
        $event.preventDefault();
      } else if (KEY_CODE === KEYS_CODE.escape) {
        this.closePopover();
        this.setFocusToButton();
      } else if (KEY_CODE === KEYS_CODE.tab) {
        $event.preventDefault();
      }
    },

    pressArrows({ isArrowDown }) {
      const ELEMENTS = this.$refs.ui_select_menu.querySelectorAll(ELEMENTS_FOR_ARROWS);
      if (ELEMENTS.length === 0) {
        return;
      }
      let isFocusInstalled = false;
      forEach(ELEMENTS, (element, index) => {
        if (element === document.activeElement) {
          isFocusInstalled = true;
          if (isArrowDown) {
            if (index < ELEMENTS.length - 1) {
              ELEMENTS[index + 1].focus();
            }
          } else {
            if (index > 0) {
              ELEMENTS[index - 1].focus();
            }
          }
          return false;
        }
      });
      if (!isFocusInstalled) {
        ELEMENTS[0].focus();
      }
    },

    setFocusForFirstElementInList() {
      const ELEMENT = this.$refs.ui_select_menu.querySelector(ELEMENTS_FOR_ARROWS);
      if (ELEMENT) {
        ELEMENT.focus();
      }
    },

    closePopover() {
      this.isOpen = false;
      this.destroyPopover();
      this.destroyEventClickOutside();
      this.destroyEventPressArrows();
    },

    setFocusToButton() {
      this.$refs.button.focus();
    },

    destroyPopover() {
      if (this.popper) {
        this.popper.destroy();
        this.popper = undefined;
      }
    },

    clickOnLabelFromParent() { // From <form-element>
      this.setFocusToButton();
    },

    clickOutsideEvent($event) {
      // here I check that click was outside the el and his children
      if (!(this.$el === $event.target ||
        this.$el.contains($event.target) ||
        this.$refs.ui_select_menu.contains($event.target))) {
        // and if it did, call method provided in attribute value
        this.clickOutsideCallback();
      }
    },

    clickOutsideCallback() {
      this.closePopover();
    },

    updatePopover() {
      setTimeout(() => {
        if (this.popper) {
          this.popper.forceUpdate();
        }
      });
    },

    itemChecked(item) {
      const currentValue = toString(this.options.keyArray ? item : item[this.keyIdLocal]);
      return (this.typeLocal !== "multiselect" && toString(this.model) === currentValue) ||
        (this.isMultiselect && find(this.model, el => toString(el) === currentValue));
    },

    getItemRef(item) {
      return this.itemChecked(item) ? "selectedItem" : "";
    },
  },
};
