import {
  isNil,
} from "lodash-es";

export function changeLinkToRoute(router) {
  document.addEventListener("click", event => {
    let target = event.target;
    while (target && target !== document) {
      if (target.matches("a")) {
        const href = target.getAttribute("href") || "";
        const targetAttribute = target.getAttribute("target");
        const IS_EMPTY_HREF = isNil(href) ||
          href === "" ||
          href.indexOf("undefined") !== -1;

        if ((event.ctrlKey ||
          event.metaKey ||
          targetAttribute === "_blank") && !IS_EMPTY_HREF) {
          return;
        }

        if (IS_EMPTY_HREF ||
          href === "#") {
          event.preventDefault();

          return;
        }

        const resolvedRoute = router.resolve(href);
        if (resolvedRoute.name) {
          event.preventDefault();
          router.push(href);
        }
        return;
      }
      target = target.parentNode;
    }
  });
}
