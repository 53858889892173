import PuxCloak from "../../PuxCloak/PuxCloak.vue";

import HttpMixin from "../../../mixins/HttpMixin";
import UIReadOnlyMixin from "../UIReadOnlyMixin";

import {
  assign,
  filter,
  get,
  isArray,
  isNil,
  isNumber,
  isString,
  join,
  keyBy,
  size,
  values,
} from "lodash-es";

export default {
  components: {
    PuxCloak,
  },
  mixins: [
    HttpMixin,
    UIReadOnlyMixin,
  ],
  watch: {
    model: function(newVal, oldVal) {
      if (!isNil(newVal) && newVal !== oldVal) {
        this.loadData();
      }
    }
  },
  data() {
    return {
      statusListLoading: undefined,
      localCollection: undefined,
    };
  },
  computed: {
    getCollection() {
      return this.getDataCollectionFromOptions || this.getDataCollection || this.localCollection || this.collection;
    },

    getDataCollectionFromOptions() {
      if (!this.options.data) {
        return undefined;
      }
      return keyBy(this.options.data, this.options.keyId);
    },

    getDataCollection() {
      if (!this.data || !this.data.length) {
        return undefined;
      }
      return keyBy(this.data, this.options.keyId);
    },

    isLoading() {
      return this.statusListLoading || this.options.loading;
    },

    optionsLocal() {
      return this.options;
    },

    notLoadListLocal() {
      return get(this.extraStatic, "notLoadList") || this.options.notLoadList;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (!this.options.url || this.notLoadListLocal) {
        return;
      }
      if (!this.options.searchGlobal) {
        this.statusListLoading = true;
        this.getListHttp({
          url: this.options.url,
          apiSaveId: this.options.apiSaveId,
          urlParams: this.options.urlParams,
          keyId: this.options.keyId
        }).then(
          response => {
            this.localCollection = response;
          },
          () => {},
        ).then(
          () => this.loadCurrentData({ searchGlobal: false }),
        );
      } else {
        this.loadCurrentData({ searchGlobal: true });
      }
    },

    isValidModelValue(value) {
      return (!this.options.emptyValue || value !== this.options.emptyValue)
             && (!this.options.extraValue && value !== this.options.extraValue)
             && (isString(value) || isNumber(value));
    },

    loadCurrentData({ searchGlobal = false }) {
      let url = get(this.options, "urlRetrieve", this.options.url);
      let urlParams = get(this.options, "urlRetrieveParams", this.options.urlParams, {});
      if (searchGlobal) {
        url = this.options.url;
        urlParams = this.options.urlParams;
      }
      if (!url) {
        return;
      }
      let searchModels = [];
      if (isArray(this.model) && size(this.model) > 0) {
        searchModels = filter(this.model, pk => this.isValidModelValue(pk));
      } else if (this.isValidModelValue(this.model)) {
        searchModels = [this.model];
      }
      searchModels = filter(searchModels, pk => isNil(this.getCollection[pk]));
      if (size(searchModels) === 0) {
        this.statusListLoading = false;
        return;
      }
      const URL_PARAMS = assign(
        urlParams,
        { [this.options.keyId]: searchModels, limit: size(searchModels) }
      );
      let saveId = undefined;
      if (!searchGlobal && this.options.apiSaveId) {
        saveId = `${ this.options.apiSaveId } ${ join(values(URL_PARAMS), "__") }`;
      }
      return this.getListHttp({
        url: url,
        urlParams: URL_PARAMS,
        keyId: this.options.keyId,
        apiSaveId: saveId
      }).then(
        response => {
          if (searchGlobal) {
            if (size(response) > 0) {
              // initiale Daten für Auswahl mit globaler Suche
              this.localCollection = assign({}, this.localCollection, response);
            }
            if (size(response) !== size(searchModels)) {
              // nicht alle initialen Daten für Auswahl mit globaler Suche gefunden
              // > nach invaliden Einträgen suchen
              this.loadCurrentData({ searchGlobal: false });
            } else {
              this.statusListLoading = false;
            }
          } else {
            // Einträge waren in der eigentlichen Liste nicht vorhanden
            // > invalide Einträge
            this.localCollection = assign({}, this.localCollection, response);
            this.statusListLoading = false;
          }
        },
        () => {},
      );
    },

  },
};
