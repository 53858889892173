import {
  computed,
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import ANotificationAPI from "aloha-vue/src/compositionAPI/ANotificationAPI";
import ATranslationAPI, {
  translation,
} from "aloha-vue/src/ATranslation/compositionAPI/ATranslationAPI";

import {
  forEach,
} from "lodash-es";

export default function SaveAPI({
  countChangedTranslations = computed(() => 0),
  getModelForSave = () => {},
  modelLanguage = ref(""),
  modelLanguageDefault = ref(""),
  resetModelDefault = () => {},
}) {
  const saving = ref(false);

  const {
    postHttp,
  } = AHttpAPI();

  const {
    addNotification,
  } = ANotificationAPI();

  const {
    setI18n,
    updateTranslation,
  } = ATranslationAPI();


  const updateTranslationsInClientGlobal = ({ response }) => {
    if (modelLanguage.value !== modelLanguageDefault.value) {
      resetModelDefault();
      return;
    }

    const TRANSLATION_OBJ = {
      ...translation,
    };
    forEach(response, item => {
      TRANSLATION_OBJ[item.ubs_text] = item.ubs_uebersetzung;
    });
    const OBJ_FOR_SAVE = {
      [modelLanguage.value]: TRANSLATION_OBJ,
    };
    setI18n(OBJ_FOR_SAVE);
    updateTranslation();
    resetModelDefault();
  };

  const save = () => {
    if (saving.value ||
      !countChangedTranslations.value) {
      return;
    }

    const MODEL_FOR_SAVE = getModelForSave();
    saving.value = true;
    const DATA = {
      sprache: modelLanguage.value,
      mapping: MODEL_FOR_SAVE,
    };
    postHttp({
      url: "uebersetzung/update_many/",
      data: DATA,
    }).then(
      response => {
        updateTranslationsInClientGlobal({ response });
        addNotification({
          text: "_MSG_MODAL_TRANSLATE_SAVE_SUCCESS_",
        });
      },
      error => {
        console.log("error", error.data);
      }
    ).finally(
      () => saving.value = false
    );
  };

  return {
    save,
    saving,
  };
}
