import { toFormElementFromParameter } from "../../../../../global/functions/mappingForParameterToFormElement";

export default {
  data() {
    return {
      jsonParam: {
        bez: "_LBL_GR_WIRKUNGSMONITOR_CONFIG_KENNZAHLEN_",
        default: null,
        help_text: "_TXT_GR_WIRKUNGSMONITOR_CONFIG_KENNZAHLEN_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: true
        },
        typ: "validated_json",
        many: true,
        as_json: false,
        unique_fields: [],
        key_field: "kennzahl",
        pos_field: null,
        min_length: 1,
        max_length: null,
        element_label: "_LBL_WM_KENNZAHL_CONFIG_ELEMENT_",
        element_template: "_LBL_WM_KENNZAHL_CONFIG_ELEMENT_{{kennzahl}}_",
        element_errors: true,
        deactivate_ordering: false,
        deactivate_preview: false,
        hide_pos: false,
        optional_single_label: "_LBL_UI_VALIDATED_JSON_SINGLE_TOGGLE_FORM_",
        optional_single_default: false,
        fields: {
          kennzahl: {
            prio: 0,
            bez: "_LBL_WM_KENNZAHL_CONFIG_FIELD_KENNZAHL_",
            default: null,
            help_text: "_TXT_WM_KENNZAHL_CONFIG_FIELD_KENNZAHL_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              displaymode: "select2"
            },
            typ: "single_choice",
            katalog: "wm_kennzahl"
          },
          ziel: {
            prio: 1,
            bez: "_LBL_WM_KENNZAHL_CONFIG_FIELD_ZIEL_",
            default: null,
            help_text: "_TXT_WM_KENNZAHL_CONFIG_FIELD_ZIEL_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false
            },
            typ: "validated_json",
            many: false,
            as_json: true,
            unique_fields: [],
            key_field: null,
            pos_field: null,
            min_length: null,
            max_length: null,
            element_label: "Element",
            element_template: "{{element_label}}",
            element_errors: false,
            deactivate_ordering: false,
            deactivate_preview: false,
            hide_pos: false,
            optional_single_label: "_LBL_WM_KENNZAHL_WERT_FIELD_TOGGLE_",
            optional_single_default: true,
            fields: {
              expression: {
                prio: 0,
                bez: "_LBL_WM_KENNZAHL_WERT_FIELD_EXPRESSION_",
                default: null,
                help_text: "_TXT_WM_KENNZAHL_WERT_FIELD_EXPRESSION_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: true,
                  max_length: null,
                  min_length: null
                },
                typ: "string"
              },
              mit_perioden: {
                prio: 1,
                bez: "_LBL_WM_KENNZAHL_WERT_FIELD_MIT_PERIODE_",
                default: false,
                help_text: "_TXT_WM_KENNZAHL_WERT_FIELD_MIT_PERIODE_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: true
                },
                typ: "boolean"
              }
            }
          },
          ist: {
            prio: 2,
            bez: "_LBL_WM_KENNZAHL_CONFIG_FIELD_IST_",
            default: null,
            help_text: "_TXT_WM_KENNZAHL_CONFIG_FIELD_IST_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false
            },
            typ: "validated_json",
            many: false,
            as_json: true,
            unique_fields: [],
            key_field: null,
            pos_field: null,
            min_length: null,
            max_length: null,
            element_label: "Element",
            element_template: "{{element_label}}",
            element_errors: false,
            deactivate_ordering: false,
            deactivate_preview: false,
            hide_pos: false,
            optional_single_label: "_LBL_WM_KENNZAHL_WERT_FIELD_TOGGLE_",
            optional_single_default: true,
            fields: {
              expression: {
                prio: 0,
                bez: "_LBL_WM_KENNZAHL_WERT_FIELD_EXPRESSION_",
                default: null,
                help_text: "_TXT_WM_KENNZAHL_WERT_FIELD_EXPRESSION_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: true,
                  max_length: null,
                  min_length: null
                },
                typ: "string"
              },
              mit_perioden: {
                prio: 1,
                bez: "_LBL_WM_KENNZAHL_WERT_FIELD_MIT_PERIODE_",
                default: false,
                help_text: "_TXT_WM_KENNZAHL_WERT_FIELD_MIT_PERIODE_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: true
                },
                typ: "boolean"
              }
            }
          }
        },
        prio: 6
      }
    };
  },
  computed: {
    jsonOptions() {
      return toFormElementFromParameter({
        obj: { [`${ this.options.id }`]: this.jsonParam },
        showLabel: true,
        staticOptions: {
          required: this.options.required,
          options: this.options.options
        }
      }).list[0];
    }
  }
};
