import {
  computed,
  toRef,
} from "vue";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

export default function HighlightAPI(props) {
  const data = toRef(props, "data");
  const modelSearch = toRef(props, "modelSearch");

  const {
    filterSearchHighlight,
  } = AFiltersAPI();

  const placeholderWithSearch = computed(() => {
    if (!modelSearch.value) {
      return data.value.placeholder;
    }

    return filterSearchHighlight(data.value.placeholder, { searchModel: modelSearch.value });
  });

  const textWithSearch = computed(() => {
    if (!modelSearch.value) {
      return data.value.text;
    }

    return filterSearchHighlight(data.value.text, { searchModel: modelSearch.value });
  });

  return {
    placeholderWithSearch,
    textWithSearch,
  };
}
